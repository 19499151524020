import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export default atom({
  key: "user-login",
  default: {
    user: {
      email: "",
      user_id: "",
      registration_complete: false,
      image_url: "",
      seller: false,
    },
    access: "",
    refresh: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const profileAtom = atom({
  key: "profile",
  default: {
    first_name: "",
    last_name: "",
    email: "",
    bio: "",
    username: "",
    image_url: "",
    bank: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_code: "",
  },
});

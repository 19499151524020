import React from "react";

const DashboardIcon = ({ fill }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 18H0V0H8V18ZM10 18H18V9H10V18ZM18 7V0H10V7H18Z" fill={fill} />
    </svg>
  );
};

export default DashboardIcon;

import React from "react";

const VideoIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="4" fill="#100E86" fillOpacity="0.1" />
      <path
        d="M24.5435 13.2056C24.4539 12.8726 24.2784 12.5691 24.0346 12.3252C23.7907 12.0814 23.4872 11.9059 23.1542 11.8163C21.9279 11.4844 17.0004 11.4844 17.0004 11.4844C17.0004 11.4844 12.0729 11.4844 10.8467 11.8163C10.5137 11.9059 10.2101 12.0814 9.96627 12.3252C9.72244 12.5691 9.54693 12.8726 9.4573 13.2056C9.22833 14.4562 9.11722 15.7255 9.12542 16.9969C9.11722 18.2682 9.22833 19.5375 9.4573 20.7881C9.54693 21.1211 9.72244 21.4247 9.96627 21.6685C10.2101 21.9124 10.5137 22.0879 10.8467 22.1775C12.0729 22.5094 17.0004 22.5094 17.0004 22.5094C17.0004 22.5094 21.9279 22.5094 23.1542 22.1775C23.4872 22.0879 23.7907 21.9124 24.0346 21.6685C24.2784 21.4247 24.4539 21.1211 24.5435 20.7881C24.7725 19.5375 24.8836 18.2682 24.8754 16.9969C24.8836 15.7255 24.7725 14.4562 24.5435 13.2056ZM15.4254 19.3594V14.6344L19.5148 16.9969L15.4254 19.3594Z"
        fill="#100E86"
      />
    </svg>
  );
};

export default VideoIcon;

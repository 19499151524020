import React from "react";

const SearchIcon = ({ fill }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1249 16.2402L12.4049 11.5202C13.5391 10.1585 14.1047 8.41194 13.9841 6.64385C13.8634 4.87577 13.0657 3.22228 11.7569 2.02736C10.4482 0.832438 8.7291 0.188084 6.95736 0.228341C5.18562 0.268599 3.49761 0.990368 2.24448 2.2435C0.991344 3.49663 0.269575 5.18465 0.229318 6.95639C0.189061 8.72813 0.833415 10.4472 2.02834 11.7559C3.22326 13.0647 4.87675 13.8624 6.64483 13.9831C8.41291 14.1038 10.1595 13.5382 11.5211 12.4039L16.2411 17.1239L17.1249 16.2402ZM1.49989 7.12391C1.49989 6.01139 1.82979 4.92386 2.44787 3.99883C3.06596 3.0738 3.94446 2.35283 4.9723 1.92709C6.00013 1.50135 7.13113 1.38995 8.22227 1.607C9.31342 1.82404 10.3157 2.35977 11.1024 3.14644C11.889 3.93311 12.4248 4.93539 12.6418 6.02653C12.8589 7.11767 12.7475 8.24867 12.3217 9.27651C11.896 10.3043 11.175 11.1828 10.25 11.8009C9.32495 12.419 8.23741 12.7489 7.12489 12.7489C5.63355 12.7473 4.20377 12.1541 3.14924 11.0996C2.09471 10.045 1.50154 8.61525 1.49989 7.12391Z"
        fill={fill || "#7F7F7F"}
      />
    </svg>
  );
};

export default SearchIcon;

export const SearchIcon2 = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9997 13.9987L11.009 11.0027M12.6663 6.9987C12.6663 8.50159 12.0693 9.94293 11.0066 11.0056C9.94391 12.0683 8.50257 12.6654 6.99967 12.6654C5.49678 12.6654 4.05544 12.0683 2.99274 11.0056C1.93003 9.94293 1.33301 8.50159 1.33301 6.9987C1.33301 5.4958 1.93003 4.05447 2.99274 2.99176C4.05544 1.92905 5.49678 1.33203 6.99967 1.33203C8.50257 1.33203 9.94391 1.92905 11.0066 2.99176C12.0693 4.05447 12.6663 5.4958 12.6663 6.9987V6.9987Z"
        stroke="#292A2E"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
    </svg>
  );
};

import { objectToQueries, objectContainsValues } from "../utils/utils";
import {
  UserAxiosInstance,
  AuthCommunitiesAxiosInstance,
  AuthUserAxiosInstance,
  CommerceAxiosInstance,
  AuthCommerceAxiosInstance,
} from "./instance";

export const Api = {
  auth: {
    signInEmail: async (data) => UserAxiosInstance.post("/auth/login", data),
    signUpEmail: async (data) => UserAxiosInstance.post("/auth/register", data),
    verifyEmail: async (data) =>
      UserAxiosInstance.post("/auth/verify-email", data),
    forgotPassword: async (data) =>
      UserAxiosInstance.post("/auth/forgot-password", data),
    resetPassword: async (data) =>
      UserAxiosInstance.post("/auth/reset-password", data),
    verifyToken: async (data) =>
      UserAxiosInstance.post("/auth/verify-token", data),
    googleAuth: async (data) => UserAxiosInstance.post("/auth/google", data),
    completeRegistration: async (data) =>
      AuthUserAxiosInstance.put("/auth/google/complete-registration", data),
    resendVerificationEmail: async (data) =>
      UserAxiosInstance.post("/auth/resend-verification-email", data),
    getNigerianBanks: async () =>
      AuthUserAxiosInstance.get("/auth/fetch-banks"),
    addSellerBankDetails: async (data) =>
      AuthUserAxiosInstance.put("/auth/become-a-seller", data),
    verifyAccountName: async (acctNumber, bankCode) => {
      return AuthUserAxiosInstance.get(
        `/auth/verify-account-number?account_number=${acctNumber}&bank_code=${bankCode}`
      );
    },
    searchUser: async (query) =>
      AuthUserAxiosInstance.get(`/auth/users?search=${query}`),
  },
  wallet: {
    getWalletDetails: async () =>
      AuthUserAxiosInstance.get("/wallets/my-wallet"),
    getTransactions: async () =>
      AuthUserAxiosInstance.get("/wallets/my-wallet/transactions"),
    withdrawFunds: async (data) =>
      AuthUserAxiosInstance.post("/wallets/my-wallet/withdraw", data),
  },
  communities: {
    createCommunity: async (data) =>
      AuthCommunitiesAxiosInstance.post("/communities/", data),
    getCommunities: async () =>
      AuthCommunitiesAxiosInstance.get("/communities/all/all"),
    getCommunitiesByUser: async () =>
      AuthCommunitiesAxiosInstance.get("/communities"),
    getCommunityDetails: async (id) =>
      AuthCommunitiesAxiosInstance.get(`/communities/${id}`),
    editCommunity: async (id, data) =>
      AuthCommunitiesAxiosInstance.put(`/communities/${id}/`, data),
    deleteCommunity: async (id) =>
      AuthCommunitiesAxiosInstance.delete(`/communities/${id}`),
    reportCommunity: async (id, data) =>
      AuthCommunitiesAxiosInstance.post(`/communities/${id}/report`, data),
    searchCommunities: async (searchValue, filters, categories) => {
      return AuthCommunitiesAxiosInstance.get(
        `/communities/all/all${
          searchValue || objectContainsValues(filters) ? "?" : ""
        }${searchValue ? "search=" + searchValue : ""}${
          searchValue && objectContainsValues(filters) ? "&" : ""
        }${objectContainsValues(filters) ? objectToQueries(filters) : ""}${
          categories.length > 0
            ? searchValue || objectContainsValues(filters)
              ? "&"
              : "?"
            : ""
        }${
          categories
            ? categories
                .map((category, index) => `category=${category}&`)
                .join("")
                .slice(0, -1)
            : ""
        }`
      );
    },
    getCommunitiesCategories: async () =>
      AuthCommunitiesAxiosInstance.get("/communities/categories/all"),
  },
  commerce: {
    bootcampCategories: async () =>
      AuthCommerceAxiosInstance.get("/bootcamps/categories/all"),
    createBootcamp: async (data) =>
      AuthCommerceAxiosInstance.post("/bootcamps/", data),
    editBootcamp: async (data, product_slug) =>
      AuthCommerceAxiosInstance.put("/bootcamps/edit/" + product_slug, data),
    getProducts: async () =>
      CommerceAxiosInstance.get("/products/published-products"),
    getSavedProducts: async () =>
      AuthCommerceAxiosInstance.get("/products/saved-products"),
    getProduct: async (id, userId) =>
      CommerceAxiosInstance.get(
        "/products/product/" + id + "?user_id=" + userId
      ),
    purchaseProduct: async (data) =>
      AuthCommerceAxiosInstance.post("/products/purchase-product", data),
    myPurchases: async (status) =>
      AuthCommerceAxiosInstance.get(
        `/products/my-purchases/${
          status !== undefined ? `?status=${status}` : ""
        }`
      ),
    myProducts: async () =>
      AuthCommerceAxiosInstance.get("/products/my-products/"),
    myProduct: async (productSlug) =>
      AuthCommerceAxiosInstance.get("/products/my-product/" + productSlug),
    updateProduct: async (data) =>
      AuthCommerceAxiosInstance.put(`/products/my-product/${data.slug}`, data),
    addTeamMember: async (data, instructorId, productSlug) =>
      AuthCommerceAxiosInstance.put(
        `/products/${productSlug}/instructors/${instructorId}/`,
        data
      ),
    deleteProduct: async (id) =>
      AuthCommerceAxiosInstance.delete("/products/my-product/" + id),
    myCustomers: async () =>
      AuthCommerceAxiosInstance.get("/products/my-customers/"),
    editCertificate: async (productSlug, data) =>
      AuthCommerceAxiosInstance.put(
        `/products/${productSlug}/certificate`,
        data
      ),
    getCertificate: async (productSlug) =>
      AuthCommerceAxiosInstance.get(`/products/${productSlug}/certificate`),
    saveProduct: async (productSlug) =>
      AuthCommerceAxiosInstance.post(`/products/${productSlug}/save`),
    removeSavedProduct: async (productSlug) =>
      AuthCommerceAxiosInstance.delete(`/products/${productSlug}/save`),
    generateCertificate: async (productSlug) =>
      AuthCommerceAxiosInstance.post(`/products/${productSlug}/certificate`),
    getMyCertificate: async (productSlug) =>
      AuthCommerceAxiosInstance.get(`/products/${productSlug}/my-certificate`),
    getUserCertificate: async (shortId) =>
      AuthCommerceAxiosInstance.get(`/products/certificate/${shortId}`),
    myProductCustomers: async (productSlug) =>
      AuthCommerceAxiosInstance.get("/products/my-customers/" + productSlug),
    mySales: async () => AuthCommerceAxiosInstance.get("/products/my-sales"),
    getStats: async () =>
      AuthCommerceAxiosInstance.get("/products/my-dashboard-finance"),
    getBuyerStats: async () =>
      AuthCommerceAxiosInstance.get("/products/my-buyer-dashboard-stats"),
    editProductStatus: async (productSlug, data) =>
      AuthCommerceAxiosInstance.put(
        "/products/my-product-status/" + productSlug,
        data
      ),
    buildCourse: async (productSlug, data) =>
      AuthCommerceAxiosInstance.put(
        "/bootcamps/course-builder/" + productSlug,
        data
      ),
    publishCourse: async (productSlug, data) =>
      AuthCommerceAxiosInstance.put(
        "/bootcamps/course-builder/" + productSlug + "?publish=true",
        data
      ),
    getCourseBuild: async (productSlug) =>
      AuthCommerceAxiosInstance.get("/bootcamps/course-builder/" + productSlug),
    getLiveClassesBootcamp: async (bootcampId) =>
      AuthCommerceAxiosInstance.get(
        "/bootcamps/live-class-contents/" + bootcampId
      ),
    getMyLiveClassContents: async (filter) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/my-live-class-contents?${filter ? `filter=${filter}` : ""}`
      ),
    getMyLiveClassContentsByDate: async (filter, date) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/my-live-class-contents-by-date?date=${date}&${
          filter ? `filter=${filter}` : ""
        }`
      ),
    getMyAssignmentSubmission: async (contentId) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/assignments/${contentId}/my-submission`
      ),
    getUserAssignmentSubmission: async (contentId, userId) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/assignments/${contentId}/user-submission/${userId}`
      ),
    getAssignmentSubmissions: async (contentId) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/assignments/${contentId}/submissions`
      ),
    getAssignment: async (contentId) =>
      AuthCommerceAxiosInstance.get(`/bootcamps/assignments/${contentId}`),
    submitAssignment: async (contentId, data) =>
      AuthCommerceAxiosInstance.put(
        `/bootcamps/assignments/${contentId}/submissions`,
        data
      ),
    getMyBootcampAssignments: async (productSlug) =>
      AuthCommerceAxiosInstance.get(`/bootcamps/${productSlug}/assignments`),
    getAssignmentsLeaderBoard: async (productSlug) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/${productSlug}/assignments/leaderboard`
      ),
    getAssignmentsLeaderBoardStats: async (productSlug) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/${productSlug}/assignments/leaderboard-stats`
      ),
    getNotSubmitted: async (productSlug, contentId) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/${productSlug}/assignments/${contentId}/not-submitted`
      ),
    gradeAssignment: async (productSlug, contentId, data) =>
      AuthCommerceAxiosInstance.post(
        `/bootcamps/${productSlug}/assignments/${contentId}/grade-submission`,
        data
      ),
    postCorrection: async (productSlug, contentId, data) =>
      AuthCommerceAxiosInstance.post(
        `/bootcamps/${productSlug}/assignments/${contentId}/correction`,
        data
      ),
    updateAssignmentPercentage: async (productSlug, data) =>
      AuthCommerceAxiosInstance.post(
        `/bootcamps/${productSlug}/assignments/update-assignment-percentage`,
        data
      ),
    getUserGrades: async (productSlug, userId) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/${productSlug}/assignments/user-grades/${userId}`
      ),
    getUserGradeStats: async (productSlug, userId) =>
      AuthCommerceAxiosInstance.get(
        `/bootcamps/${productSlug}/assignments/user-grade-stats/${userId}`
      ),
  },
  profile: {
    getPublicProfile: async (username) =>
      UserAxiosInstance.get(`/profile/public/${username}/`),
    getProfile: async () => AuthUserAxiosInstance.get("/auth/profile"),
    editProfile: async (data) =>
      AuthUserAxiosInstance.put("/auth/profile", data),
    getEducation: async () => AuthUserAxiosInstance.get("/profile/educations/"),
    addEducation: async (data) =>
      AuthUserAxiosInstance.post("/profile/educations/", data),
    updateEducation: async (data) =>
      AuthUserAxiosInstance.put(`profile/educations/${data.id}/`, data),
    getExperience: async () =>
      AuthUserAxiosInstance.get("/profile/experiences/"),
    addExperience: async (data) =>
      AuthUserAxiosInstance.post("/profile/experiences/", data),
    updateExperience: async (data) =>
      AuthUserAxiosInstance.put(`profile/experiences/${data.id}/`, data),
    getPortfolio: async () => AuthUserAxiosInstance.get("/profile/portfolios/"),
    addPortfolio: async (data) =>
      AuthUserAxiosInstance.post("/profile/portfolios/", data),
    updatePortfolio: async (data) =>
      AuthUserAxiosInstance.put(`profile/portfolios/${data.id}/`, data),
    getLink: async () => AuthUserAxiosInstance.get("/profile/links/"),
    addLink: async (data) =>
      AuthUserAxiosInstance.post("/profile/links/", data),
    updateLink: async (data) =>
      AuthUserAxiosInstance.put(`/profile/links/${data.id}/`, data),
    deleteEducation: async (id) =>
      AuthUserAxiosInstance.delete(`/profile/educations/${id}`),
    deleteExperience: async (id) =>
      AuthUserAxiosInstance.delete(`/profile/experiences/${id}`),
    deletePortfolio: async (id) =>
      AuthUserAxiosInstance.delete(`/profile/portfolios/${id}`),
    deleteLink: async (id) =>
      AuthUserAxiosInstance.delete(`/profile/links/${id}`),
  },
  data: {
    uploadFile: async (data) =>
      AuthUserAxiosInstance.post("/upload-file", data),
  },
  settings: {
    changeEmail: async (data) =>
      AuthUserAxiosInstance.put("/auth/change-email", data),
    changePassword: async (data) =>
      AuthUserAxiosInstance.post("/auth/change-password", data),
    changeUsername: async (data) =>
      AuthUserAxiosInstance.put("/auth/change-username", data),
    verifyEmailChange: async (data) =>
      AuthUserAxiosInstance.post("/auth/verify-email-change", data),
    getNotificationSettings: async () =>
      AuthUserAxiosInstance.get("/notifications/settings"),
    updateNotificationSettings: async (data) =>
      AuthUserAxiosInstance.put("/notifications/settings", data),
    updateBankDetails: async (data) =>
      AuthUserAxiosInstance.put("/auth/update-bank-details", data),
  },
  notification: {
    getNotifications: async () => AuthUserAxiosInstance.get("/notifications/"),
    updateNotificationView: async (id) =>
      AuthUserAxiosInstance.post(`/notifications/${id}/view`),
    updateFcmToken: async (data) =>
      AuthUserAxiosInstance.post(`/notifications/fcm-token`, data),
  },
  users: {
    getReviews: async (userId) =>
      UserAxiosInstance.get("/reviews/user/" + userId),
    getReviewData: async (userId) =>
      UserAxiosInstance.get("/reviews/user/" + userId + "/review-data"),
    review: async (userId, data) =>
      AuthUserAxiosInstance.post("/reviews/user/" + userId, data),
    checkReview: async (userId, itemSlug) =>
      AuthUserAxiosInstance.get(
        `/reviews/check-review?user_id=${userId}&item_slug=${itemSlug}`
      ),
  },
};

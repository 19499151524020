import React from "react";

const TextIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="4" fill="#100E86" fillOpacity="0.1" />
      <path
        d="M22.25 10.25H11.7425C10.9175 10.25 10.25 10.925 10.25 11.75L10.2575 22.25C10.2575 23.075 10.925 23.75 11.75 23.75H19.25L23.75 19.25V11.75C23.75 10.925 23.075 10.25 22.25 10.25ZM14 14H20C20.4125 14 20.75 14.3375 20.75 14.75C20.75 15.1625 20.4125 15.5 20 15.5H14C13.5875 15.5 13.25 15.1625 13.25 14.75C13.25 14.3375 13.5875 14 14 14ZM16.25 18.5H14C13.5875 18.5 13.25 18.1625 13.25 17.75C13.25 17.3375 13.5875 17 14 17H16.25C16.6625 17 17 17.3375 17 17.75C17 18.1625 16.6625 18.5 16.25 18.5ZM18.5 22.625V19.25C18.5 18.8375 18.8375 18.5 19.25 18.5H22.625L18.5 22.625Z"
        fill="#100E86"
      />
    </svg>
  );
};

export default TextIcon;

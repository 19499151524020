import { Listbox } from "@headlessui/react";
import { ReactComponent as ArrowDown } from "../../assets/svgs/arrow-down.svg";

const FilterSelect = ({ selected, setSelected, options }) => {
  const hasValueObj = selected.value ? true : false;

  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative mt-1">
        <Listbox.Button
          className={`relative cursor-pointer w-[5.6rem] text-left rounded-full py-2 px-3 border border-filter text-filter-text text-xs sm:text-[.7rem] ${
            hasValueObj ? "bg-tech-blue1 text-[#F5F6FF] border-0" : ""
          }`}
        >
          <span className="block truncate">{selected.name}</span>
          <span className="absolute inset-y-0 right-0 flex items-center px-2">
            {" "}
            <ArrowDown
              className={`${hasValueObj ? "fill-[#F5F6FF]" : ""}`}
            />{" "}
          </span>
        </Listbox.Button>
        <>
          <Listbox.Options className="absolute mt-1 max-h-60 w-[8rem] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
            {options.map((collectibles, collectiblesIdx) => (
              <Listbox.Option
                key={collectiblesIdx}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2  px-4 ${
                    active ? "bg-[#F5F6FF] text-tech-blue1" : "text-gray-900"
                  }`
                }
                value={collectibles}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                      style={collectibles?.style}
                    >
                      {collectibles.name}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </>
      </div>
    </Listbox>
  );
};

export default FilterSelect;

import React from "react";

const ProductsIcon = ({ fill }) => {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 3H0.75C0.3375 3 0 3.3375 0 3.75V20.25C0 20.6625 0.3375 21 0.75 21H5.25C5.6625 21 6 20.6625 6 20.25V3.75C6 3.3375 5.6625 3 5.25 3ZM4.5 7.5H1.5V6H4.5V7.5ZM12.75 3H8.25C7.8375 3 7.5 3.3375 7.5 3.75V20.25C7.5 20.6625 7.8375 21 8.25 21H12.75C13.1625 21 13.5 20.6625 13.5 20.25V3.75C13.5 3.3375 13.1625 3 12.75 3ZM12 7.5H9V6H12V7.5Z"
        fill={fill}
      />
      <path
        d="M17.9303 4.16079L13.9118 6.18579C13.7348 6.27576 13.6005 6.432 13.5381 6.6205C13.4757 6.809 13.4904 7.0145 13.5788 7.19229L20.3288 20.5873C20.4187 20.7643 20.575 20.8986 20.7635 20.9609C20.952 21.0233 21.1575 21.0087 21.3353 20.9203L25.3538 18.8953C25.5307 18.8053 25.665 18.6491 25.7274 18.4606C25.7898 18.2721 25.7752 18.0666 25.6868 17.8888L18.9368 4.49379C18.8468 4.3168 18.6905 4.18251 18.502 4.12014C18.3135 4.05778 18.108 4.07238 17.9303 4.16079Z"
        fill={fill}
      />
      <path
        d="M21.75 20.25C21.75 20.4489 21.671 20.6397 21.5303 20.7803C21.3897 20.921 21.1989 21 21 21C20.8011 21 20.6103 20.921 20.4697 20.7803C20.329 20.6397 20.25 20.4489 20.25 20.25C20.25 20.0511 20.329 19.8603 20.4697 19.7197C20.6103 19.579 20.8011 19.5 21 19.5C21.1989 19.5 21.3897 19.579 21.5303 19.7197C21.671 19.8603 21.75 20.0511 21.75 20.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProductsIcon;

import { toast } from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import { Api } from "../api/Api";
import { filteredCommunitiesAtom } from "../atoms/communities.atom";
import { handleToastError } from "../utils/utils";

export function useCommunities() {
  const queryClient = useQueryClient();

  const usePostCommunities = useMutation(
    (data) => {
      return Api.communities.createCommunity(data);
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("communities");
      },
    }
  );

  const useGetCommunities = () => {
    return useQuery("communities", async () => {
      return Api.communities.getCommunities();
    });
  };
  const useGetCommunitiesByUser = () => {
    return useQuery("communities_by_user", async () => {
      return Api.communities.getCommunitiesByUser();
    });
  };

  const useGetIndividualCommunityDetails = (id) => {
    return useQuery(["communities-details", id], async () => {
      return Api.communities.getCommunityDetails(id);
    });
  };

  const useDeleteCommunity = () => {
    return useMutation((id) => Api.communities.deleteCommunity(id), {
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (err) => {
        toast.error(err);
      },
    });
  };

  const useEditCommunity = () => {
    return useMutation(
      ({ id, data }) => Api.communities.editCommunity(id, data),
      {
        onSuccess: ({ data }) => {
          toast.success(data.message);
        },
        onError: (err) => {
          toast.error(err);
        },
      }
    );
  };

  const useReportCommunity = () => {
    return useMutation(
      ({ id, data }) => Api.communities.reportCommunity(id, data),
      {
        onSuccess: ({ data }) => {
          toast.success(data.message);
        },
        onError: (err) => {
          handleToastError(err);
        },
      }
    );
  };

  return {
    usePostCommunities,
    useReportCommunity,
    useDeleteCommunity,
    useGetCommunities,
    useGetIndividualCommunityDetails,
    useGetCommunitiesByUser,
    useEditCommunity,
  };
}

export const useGetFilteredCommunities = () => {
  const queryClient = useQueryClient();
  const setCommunities = useSetRecoilState(filteredCommunitiesAtom);
  return useMutation(
    ({ searchValue, filters, categories }) =>
      Api.communities.searchCommunities(searchValue, filters, categories),
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries("communities");
        setCommunities(data.data);
      },
      onError: (err) => {
        toast.error(err);
      },
    }
  );
};
export const useGetCommunitiesCategories = () => {
  return useQuery(
    "categories",
    async () => await Api.communities.getCommunitiesCategories()
  );
};

import React from "react";

const CustomersIcon = ({ fill }) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.40002 7.59844C8.3548 7.59844 9.27047 7.21915 9.9456 6.54402C10.6207 5.86889 11 4.95322 11 3.99844C11 3.04366 10.6207 2.12798 9.9456 1.45285C9.27047 0.777722 8.3548 0.398438 7.40002 0.398438C6.44524 0.398438 5.52957 0.777722 4.85443 1.45285C4.1793 2.12798 3.80002 3.04366 3.80002 3.99844C3.80002 4.95322 4.1793 5.86889 4.85443 6.54402C5.52957 7.21915 6.44524 7.59844 7.40002 7.59844ZM16.4 8.79844C17.1957 8.79844 17.9587 8.48237 18.5213 7.91976C19.0839 7.35715 19.4 6.59409 19.4 5.79844C19.4 5.00279 19.0839 4.23973 18.5213 3.67712C17.9587 3.11451 17.1957 2.79844 16.4 2.79844C15.6044 2.79844 14.8413 3.11451 14.2787 3.67712C13.7161 4.23973 13.4 5.00279 13.4 5.79844C13.4 6.59409 13.7161 7.35715 14.2787 7.91976C14.8413 8.48237 15.6044 8.79844 16.4 8.79844ZM0.93802 17.712C0.702743 17.5654 0.513768 17.3552 0.39294 17.1057C0.272112 16.8562 0.224365 16.5775 0.25522 16.302C0.476446 14.5633 1.32412 12.9649 2.63939 11.8064C3.95467 10.6479 5.64727 10.0087 7.40002 10.0087C9.15277 10.0087 10.8454 10.6479 12.1606 11.8064C13.4759 12.9649 14.3236 14.5633 14.5448 16.302C14.6144 16.8624 14.3384 17.406 13.8608 17.7108C11.9327 18.9464 9.69005 19.6016 7.40002 19.5984C5.1097 19.6022 2.8666 18.9474 0.93802 17.712ZM16.4 17.1984H16.2728C16.3568 16.842 16.3784 16.4652 16.3304 16.0788C16.1253 14.4292 15.4655 12.8691 14.4248 11.5728C15.0946 11.3093 15.8102 11.1822 16.5298 11.1991C17.2493 11.216 17.9582 11.3765 18.6148 11.6712C19.2715 11.9659 19.8626 12.3888 20.3535 12.9151C20.8445 13.4414 21.2253 14.0605 21.4736 14.736C21.5499 14.9517 21.5485 15.1872 21.4696 15.4019C21.3908 15.6166 21.2394 15.7971 21.0416 15.912C19.6409 16.7565 18.0356 17.2014 16.4 17.1984Z"
        fill={fill}
      />
    </svg>
  );
};

export default CustomersIcon;

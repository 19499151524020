import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCommunities } from "../../hooks/communities.hook";
import Loader, { PageLoader } from "../../components/loader";
import { BackArrow } from "../../assets/icons/arrow";
import { useRecoilState } from "recoil";
import {
  communityPlatforms,
  platformsImg,
  communityStatuses,
} from "../../utils/constants";
import userAtom from "../../atoms/user.atom";
import Modal from "../../components/modal";
import { refreshAtom } from "../../atoms/refresh.atom";

const CommunitiesDetails = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const userProfile = useRecoilState(userAtom);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    useGetIndividualCommunityDetails,
    useDeleteCommunity,
    useReportCommunity,
  } = useCommunities();
  const { data, isLoading } = useGetIndividualCommunityDetails(id);
  const community_details = data?.data?.data;

  const [refresh, setRefresh] = useRecoilState(refreshAtom);

  const { mutate, isLoading: deleteCommunityLoading } = useDeleteCommunity();
  const { mutate: reportMutate, isLoading: reportCommunityLoading } =
    useReportCommunity();

  const currentUserID = userProfile[0]?.user?.user_id;
  const communityPostCreatorID = community_details?.creator?.id;

  const [deleteModal, setDeleteModal] = useState(false);

  const [reportModal, setReportModal] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleCloseReportModal = () => {
    setReportModal(false);
  };
  const handleDeleteCommunity = (id) => {
    mutate(id, {
      onSuccess: () => {
        handleCloseDeleteModal();
        navigate("/communities");
        setRefresh(!refresh);
      },
    });
  };

  const [reportReason, setReportReason] = useState("");

  const handleReportCommunity = (id) => {
    reportMutate(
      { id, data: { reason: reportReason } },
      {
        onSuccess: () => {
          handleCloseReportModal();
          setReportReason("");
        },
      }
    );
  };

  if (isLoading) {
    return (
      <div className="w-full xl:col-span-8">
        <Loader />
      </div>
    );
  }
  return (
    <main className=" lg:col-span-8 xl:col-span-8  p-2 z-1 flex-shrink-0 flex rounded-md  bg-white shadow h-[85vh] overflow-scroll scrollbar-none ">
      <div className="w-full">
        <div className="flex p-2   justify-between items-center flex-row rounded-md  z-2 ">
          <div
            className="revert lg:hidden "
            onClick={() => navigate("/communities")}
          >
            <BackArrow />
          </div>
        </div>
        <div className=" flex justify-between py-5 px-5">
          <div></div>
          <div className="px-4  text-center sm:px-6">
            <h3 className="text-lg leading-6 font-semibold text-gray-900">
              {community_details?.name}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 flex gap-x-2 items-center justify-center">
              <img
                src={community_details?.creator?.image_url}
                alt=""
                className=" w-6 h-6 rounded-full object-cover"
              />
              {community_details?.creator?.first_name}
              {community_details?.creator?.last_name}
            </p>
          </div>
          <div className="">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="p-1 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-6 w-70 " aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                  <div className="py-1">
                    {/* report or edit content */}
                    {currentUserID === communityPostCreatorID ? (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={`/edit-community/${community_details?.id}`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Edit
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                              onClick={() => setDeleteModal(true)}
                            >
                              Delete
                            </p>
                          )}
                        </Menu.Item>
                      </>
                    ) : (
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            onClick={() => setReportModal(true)}
                          >
                            Report
                          </p>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-details-bg rounded-md px-4 py-5 my-2  sm:gap-4 sm:px-6">
              <p className="text-sm font-bold text-gray-900">Description</p>
              <div className="mt-2 text-sm text-gray-900 sm:col-span-2">
                {community_details?.description}
              </div>
            </div>

            <div className="bg-details-bg rounded-md px-4 py-5  my-2 sm:px-6">
              <p className="text-sm font-bold text-gray-900">Platform</p>
              <div className="mt-1 text-sm text-gray-900 sm:col-span-2 flex gap-2 items-center">
                {platformsImg[community_details?.platform]}
                {communityPlatforms[community_details?.platform]} Group
              </div>
            </div>
            <div className="bg-details-bg rounded-md px-4 py-5  my-2 sm:px-6">
              <p className="text-sm font-bold text-gray-900">
                Community Status
              </p>
              <div className="mt-1 text-sm text-gray-900 sm:col-span-2 flex gap-2 items-center">
                {communityStatuses[community_details?.status]}
              </div>
            </div>
            <div className="bg-details-bg rounded-md px-4 py-5 my-2  sm:gap-4 sm:px-6">
              <p className="text-sm font-bold text-gray-900">
                Invite Link / Instruction
              </p>
              <div className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {community_details?.status === "open_community" ? (
                  <a
                    href={community_details?.inviteLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="break-words text-tech-blue1"
                  >
                    {community_details?.inviteLink}
                  </a>
                ) : community_details?.status === "closed_community" ? (
                  <div className="mt-1 text-sm text-gray-900 sm:col-span-2 break-words">
                    {community_details?.instructions}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="bg-details-bg rounded-md px-4 py-5 my-2  sm:gap-4 sm:px-6">
              <p className="text-sm font-bold text-gray-900">Categories</p>
              <div className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <>
                  {community_details?.categories?.map((category) => (
                    <span
                      key={category.id}
                      className={`inline-flex m-1 items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-300 text-gray-800`}
                    >
                      {category.name}
                    </span>
                  ))}
                </>
              </div>
            </div>
            <div className="bg-details-bg rounded-md px-4 py-5 my-2  sm:gap-4 sm:px-6">
              <p className="text-sm font-bold text-gray-900">Tags</p>
              <div className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <>
                  {community_details?.tags?.map((tag) => (
                    <span
                      key={tag}
                      className={`inline-flex m-1 items-center px-4 py-1 rounded-md text-sm font-medium bg-gray-300 text-gray-800`}
                    >
                      {tag}
                    </span>
                  ))}
                </>
              </div>
            </div>
          </dl>
        </div>
      </div>
      <Modal open={deleteModal} handleClose={handleCloseDeleteModal}>
        <div className=" bg-white rounded-xl w-[97vw] md:w-[50vw] lg:w-[30vw] p-4 text-center">
          <p className=" text-[#DD0101] font-bold">Delete</p>
          <p className=" pt-6 w-[80%] mx-auto">
            Are you sure you want to delete this community? This cannot be
            reversed
          </p>

          <div className=" pt-6 flex gap-10 mx-auto w-max">
            <button
              className="bg-white rounded-[1.25rem] py-3 px-12 text-[#100E86] font-bold text-xs"
              onClick={() => handleDeleteCommunity(community_details?.id)}
            >
              Yes
            </button>
            <button
              className="bg-[#100E86] rounded-[1.25rem] py-3 px-12 text-white font-bold text-xs"
              onClick={() => handleCloseDeleteModal()}
            >
              No
            </button>
          </div>
        </div>
      </Modal>

      <Modal open={reportModal} handleClose={handleCloseReportModal}>
        <div className=" bg-white rounded-xl w-[97vw] md:w-[50vw] lg:w-[30vw] p-4 text-center">
          <p className="  font-bold">Report</p>
          <p className=" pt-6 w-[80%] mx-auto">
            Why do you want to report this community?
          </p>

          <form
            className=" pt-6 "
            onSubmit={(e) => {
              e.preventDefault();
              handleReportCommunity(community_details?.id);
            }}
          >
            <textarea
              className="w-full outline-none resize-none border-[#100E86] border h-40"
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
              required
            ></textarea>

            <button
              className="bg-[#100E86] rounded-[1.25rem] py-3 px-12 text-white font-bold text-xs"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </Modal>
      <PageLoader
        notLoading={!(deleteCommunityLoading || reportCommunityLoading)}
      />
    </main>
  );
};

export default CommunitiesDetails;

import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Api } from "../api/Api";
import userAtom from "../atoms/user.atom";
import errorAtom from "../atoms/error.atom";
import { EMAIL_NOT_VERIFIED_ERROR } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Auth } from "../utils/storage";
import {
  cachePreLocation,
  checkJwtExpiry,
  handleToastError,
} from "../utils/utils";

export const useRegister = () => {
  return useMutation((data) => Api.auth.signUpEmail(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      handleToastError(err);
    },
  });
};

export const useLogin = () => {
  const setUserData = useSetRecoilState(userAtom);
  const setErrorMessage = useSetRecoilState(errorAtom);
  return useMutation((data) => Api.auth.signInEmail(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
      setUserData(data.data);
    },
    onError: (err) => {
      if (err === EMAIL_NOT_VERIFIED_ERROR) {
        setErrorMessage({ message: EMAIL_NOT_VERIFIED_ERROR });
      } else {
        handleToastError(err);
      }
    },
  });
};

export const useResendEmail = () => {
  return useMutation((data) => Api.auth.resendVerificationEmail(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      handleToastError(err);
    },
  });
};

export const useVerifyEmail = () => {
  return useMutation((data) => Api.auth.verifyEmail(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      handleToastError(err);
    },
    retry: false,
  });
};

export const useForgotPassword = () => {
  return useMutation((data) => Api.auth.forgotPassword(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      handleToastError(err);
    },
    retry: false,
  });
};

export const useResetPassword = () => {
  return useMutation((data) => Api.auth.resetPassword(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      handleToastError(err);
    },
    retry: false,
  });
};

export const useVerifyToken = () => {
  return useMutation((data) => Api.auth.verifyToken(data), {
    retry: false,
  });
};

export const useGoogleLogin = () => {
  const setUserData = useSetRecoilState(userAtom);
  return useMutation((data) => Api.auth.googleAuth(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
      setUserData(data.data);
    },
    onError: (err) => {
      handleToastError(err);
    },
  });
};

export const useCompleteRegistration = () => {
  return useMutation((data) => Api.auth.completeRegistration(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      handleToastError(err);
    },
  });
};

export const useCheckLoggedInHook = (currentRoute, communities = true) => {
  const navigate = useNavigate();

  const userData = useRecoilValue(userAtom);
  useEffect(() => {
    (async () => {
      const token = Auth.getToken();
      if (token) {
        if (checkJwtExpiry(token)) {
          if (!userData?.user?.registration_complete) {
            toast.error("Please complete profile");
            cachePreLocation(currentRoute);
            navigate("/complete-registration");
          } else {
            const preLocation = localStorage.getItem("preLocation");
            if (preLocation) {
              localStorage.removeItem("preLocation");
              navigate(preLocation);
            } else {
              navigate(communities ? "/communities" : currentRoute);
            }
          }
        } else {
          navigate(currentRoute);
        }
      } else {
        navigate(currentRoute);
      }
    })();
  }, []);
};

export const useSearchUser = (query) => {
  return useMutation((query) => Api.auth.searchUser(query), {
    onError: (err) => {
      handleToastError(err);
    },
  });
};

import axios from "axios";
import {
  USER_API_URL,
  COMMUNITIES_API_URL,
  COMMERCE_API_URL,
} from "../utils/constants";
import { Auth } from "../utils/storage";
import { handle401 } from "../utils/utils";

export const UserAxiosInstance = axios.create({
  baseURL: USER_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

UserAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);

export const AuthUserAxiosInstance = axios.create({
  baseURL: USER_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

AuthUserAxiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
    };
    config.headers.authorization = `Bearer ${Auth.getToken()}`;
    config.timeout = 120000;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

AuthUserAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.status === 401) {
      handle401();
    }
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);

//  Communities

export const CommunitiesAxiosInstance = axios.create({
  baseURL: COMMUNITIES_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

CommunitiesAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);

export const AuthCommunitiesAxiosInstance = axios.create({
  baseURL: COMMUNITIES_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

AuthCommunitiesAxiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
    };
    config.headers.authorization = `Bearer ${Auth.getToken()}`;
    config.timeout = 120000;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

AuthCommunitiesAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.status === 401) {
      handle401();
    }
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);

//  Commerce

export const CommerceAxiosInstance = axios.create({
  baseURL: COMMERCE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

CommerceAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);

export const AuthCommerceAxiosInstance = axios.create({
  baseURL: COMMERCE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

AuthCommerceAxiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
    };
    config.headers.authorization = `Bearer ${Auth.getToken()}`;
    config.timeout = 120000;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

AuthCommerceAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.status === 401) {
      handle401();
    }
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);

import React, { useState, lazy, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Notfound from "./pages/not-found";
import ProtectedRoutes, {
  ProtectedSellerRoutes,
} from "./components/protected-routes";
import PageLayout from "./components/page-layout";
import { useRecoilValue } from "recoil";
import pageHeaderAtom from "./atoms/page-header.atom";
import { PageLoader } from "./components/loader";

const AddCommunity = lazy(() => import("./pages/add-community"));
const Home = lazy(() => import("./pages/home"));
const Login = lazy(() => import("./pages/login"));
const Register = lazy(() => import("./pages/register"));
const VerifyEmail = lazy(() => import("./pages/verify-email"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
const Communities = lazy(() => import("./pages/communities"));
const Profile = lazy(() => import("./pages/profile"));
const CommunitiesDetails = lazy(() =>
  import("./pages/communities/communities-details")
);
const CompleteRegistration = lazy(() =>
  import("./pages/complete-registration")
);
const EditCommunity = lazy(() => import("./pages/edit-community"));
const BuyersDashboard = lazy(() => import("./pages/buyer-dashboard"));
const BecomeSellerForm = lazy(() => import("./pages/become-seller"));
const CreateBootcamp = lazy(() => import("./pages/bootcamp"));
const SellerDashboard = lazy(() => import("./pages/seller-dashboard"));
const AddAProduct = lazy(() => import("./pages/add-a-product"));
const TransactionHistory = lazy(() => import("./pages/transaction-history"));
const PayoutRequest = lazy(() => import("./pages/payout-request"));
const Sales = lazy(() => import("./pages/sales"));
const MyCalendar = lazy(() => import("./pages/calendar"));
const Products = lazy(() => import("./pages/products"));
const Customers = lazy(() => import("./pages/customers"));
const Explore = lazy(() => import("./pages/explore"));
const Purchases = lazy(() => import("./pages/purchases"));
const ViewBootcamp = lazy(() => import("./pages/bootcamp/view-bootcamp"));
const CourseManager = lazy(() => import("./pages/course-manager"));
const CourseBuilder = lazy(() => import("./pages/course-builder"));
const CourseOverview = lazy(() => import("./pages/course-overview"));
const CourseView = lazy(() => import("./pages/course-view"));
const Settings = lazy(() => import("./pages/settings"));
const PublicUserProfile = lazy(() => import("./pages/public-user-profile"));
const Notifications = lazy(() => import("./pages/notifications"));
const FeaturesPage = lazy(() => import("./pages/features-page"));
const Pricing = lazy(() => import("./pages/pricing"));
const FAQ = lazy(() => import("./pages/faq"));
const EditBootcamp = lazy(() => import("./pages/edit-bootcamp"));
const UserGrades = lazy(() => import("./pages/course-manager/user-grades"));
const CreateCertificate = lazy(() =>
  import("./pages/course-manager/create-certificate")
);
const Verify = lazy(() => import("./pages/verify"));
const AssignmentGrade = lazy(() =>
  import("./pages/course-manager/assignment-grade")
);
const GeneralCorrection = lazy(() =>
  import("./pages/course-manager/correction")
);
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy"));
const TermsAndConditions = lazy(() => import("./pages/terms-and-conditions"));

function App() {
  const [addCommunityModal, setAddCommunityModal] = useState(false);
  const headerItem = useRecoilValue(pageHeaderAtom);

  return (
    <div className="App font-openSans">
      <Routes>
        <Route
          exact
          path="/"
          element={<Suspense fallback={<PageLoader />} children={<Outlet />} />}
        >
          {["/users/", "/sellers", "/"].map((path) => (
            <Route exact key={path} path={path} element={<Home />} />
          ))}

          {["/users/features", "/sellers/features"].map((path) => (
            <Route exact key={path} path={path} element={<FeaturesPage />} />
          ))}

          <Route exact path="/sellers/pricing" element={<Pricing />} />
          {["/users/faq", "/sellers/faq"].map((path) => (
            <Route exact key={path} path={path} element={<FAQ />} />
          ))}

          {["/users/login", "/sellers/login", "/login", "/login"].map(
            (path) => (
              <Route exact key={path} path={path} element={<Login />} />
            )
          )}
          <Route exact path="/signup" element={<Register />} />
          <Route exact path="/verify-email" element={<VerifyEmail />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />

          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/"
            element={
              <PageLayout header={headerItem.title} back={headerItem.back} />
            }
          >
            <Route path="/explore" element={<Explore />} />
            <Route
              path="/view-bootcamp/:productSlug"
              element={<ViewBootcamp />}
            />
            <Route path="/verify/:certificateId" element={<Verify />} />
          </Route>

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          <Route exact path="*" element={<Notfound />} />
        </Route>
        <Route exact path="/" element={<ProtectedRoutes />}>
          <Route
            exact
            path="/"
            element={
              <Suspense fallback={<PageLoader />} children={<Outlet />} />
            }
          >
            <Route
              exact
              path="/complete-registration"
              element={<CompleteRegistration />}
            />
            <Route path="/course-view/:productSlug" element={<CourseView />} />

            <Route exact path="/" element={<ProtectedSellerRoutes />}>
              <Route
                path="/course-builder/:productSlug"
                element={<CourseBuilder />}
              />
            </Route>
          </Route>

          <Route
            exact
            path="/"
            element={
              <PageLayout header={headerItem.title} back={headerItem.back} />
            }
          >
            {" "}
            <Route path="/become-seller" element={<BecomeSellerForm />} />
            <Route path="/purchases" element={<Purchases />} />
            <Route exact path="/" element={<ProtectedSellerRoutes />}>
              <Route path="/add-a-product" element={<AddAProduct />} />
              <Route path="/create-bootcamp" element={<CreateBootcamp />} />
              <Route path="/seller-dashboard" element={<SellerDashboard />} />
              <Route path="/payout-request" element={<PayoutRequest />} />
              <Route path="/sales" element={<Sales />} />
              <Route path="/products" element={<Products />} />
              <Route path="/customers" element={<Customers />} />
              <Route
                path="/course-manager/:productSlug"
                element={<CourseManager />}
              />
              <Route
                path="/course-manager/:productSlug/grades/:userId"
                element={<UserGrades />}
              />
              <Route
                path="/course-manager/:productSlug/assignment/:assignmentId/grade-assignment/:userId"
                element={<AssignmentGrade />}
              />
              <Route
                path="/course-manager/:productSlug/assignment/:assignmentId/correction"
                element={<GeneralCorrection />}
              />
              <Route
                path="/course-manager/:productSlug/edit"
                element={<EditBootcamp />}
              />
              <Route
                path="/course-manager/:productSlug/create-certificate"
                element={<CreateCertificate />}
              />
            </Route>
            <Route path="/calendar" element={<MyCalendar />} />
            <Route
              path="/transaction-history"
              element={<TransactionHistory />}
            />
            <Route
              path="/course-overview/:productSlug"
              element={<CourseOverview />}
            />
            <Route
              exact
              path="/communities"
              element={
                <Communities {...{ addCommunityModal, setAddCommunityModal }} />
              }
            >
              <Route path="/communities/:id" element={<CommunitiesDetails />} />
            </Route>
            <Route
              exact
              path="/profile"
              element={
                <Profile {...{ addCommunityModal, setAddCommunityModal }} />
              }
            />
            <Route path="/buyer-dashboard" element={<BuyersDashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/add-communities" element={<AddCommunity />} />
            <Route path="/edit-community/:id" element={<EditCommunity />} />
          </Route>
        </Route>

        <Route
          exact
          path="/"
          element={<Suspense fallback={<PageLoader />} children={<Outlet />} />}
        >
          <Route path="/:username" element={<PublicUserProfile />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import { RecoilRoot } from "recoil";
import { GoogleGsi } from "./components/google";
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools /> */}
      <Router>
        <GoogleGsi />
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 5000,
            success: {
              style: {
                background: "#F5F6FD",
              },
            },
            error: {
              style: {
                background: "#FFF3F3",
              },
            },
            style: {
              borderRadius: "8px",
              maxWidth: "366px",
              minHeight: "48px",
              boxShadow: "none",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "17px",
              letterSpacing: "0.01em",
              color: "#000B14",
              textAlign: "left",
              flex: "unset",
            },
          }}
        />
        <App />
      </Router>
    </QueryClientProvider>
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

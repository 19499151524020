import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "../../hooks/auth.hook";
import { GOOGLE_CLIENT_ID } from "../../utils/constants";
import { Auth } from "../../utils/storage";

const GoogleButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate, isLoading } = useGoogleLogin();

  const handleGoogleResponse = async (response) => {
    mutate(
      { credential: response.credential, platform: "web" },
      {
        onSuccess: () => {
          if (["/login", "/register"].includes(location.pathname)) {
            navigate("/communities");
          } else {
            window.location.reload();
          }
        },
      }
    );
  };

  const handleFailure = (error) => {
    console.log(error);
  };

  const initializeGSI = () => {
    try {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleGoogleResponse,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("google-button"),
        {
          type: "standard",
          text: "continue_with",
        }
      );
    } catch (error) {
      handleFailure(error);
    }
  };

  useEffect(() => {
    const el = document.createElement("script");
    el.setAttribute("src", "https://accounts.google.com/gsi/client");
    document.querySelector("body")?.appendChild(el);
    el.onload = () => initializeGSI();
  }, []); // eslint-disable-line

  return (
    <>
      <div id="google-button" className=""></div>
    </>
  );
};

export default GoogleButton;

export const GoogleGsi = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useGoogleLogin();
  const location = useLocation();

  const handleGoogleResponse = async (response) => {
    mutate(
      { credential: response.credential, platform: "web" },
      {
        onSuccess: () => {
          if (["/login", "/register", "/"].includes(location.pathname)) {
            navigate("/communities");
          } else {
            window.location.reload();
          }
        },
      }
    );
  };

  const initializeGSI = () => {
    if (!Auth.getToken()) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleGoogleResponse,
      });
      document.cookie =
        "g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      window.google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed()) {
          console.error("No google sessions");
        }
      });
    }
  };

  useEffect(() => {
    const el = document.createElement("script");
    el.setAttribute("src", "https://accounts.google.com/gsi/client");
    document.querySelector("body")?.appendChild(el);
    el.onload = () => initializeGSI();
  }, []); // eslint-disable-line
  return <></>;
};

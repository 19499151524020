import React, { useState } from "react";

import { EyesClosed, EyesOpen } from "../../assets/icons/eyes";

import LinkIcon from "../../assets/icons/link";
import { Editor } from "react-draft-wysiwyg";
import { ArrowDown } from "../../assets/icons/arrow";
import { useSingleFileUpload } from "../../hooks/selectFiles.hook";
import toast from "react-hot-toast";

const InputField = ({ label, ...props }) => {
  const [eyes, setEyes] = useState(false);
  return (
    <>
      {label ? (
        <label className=" font-[600] mb-3" htmlFor={props.id}>
          {label}
        </label>
      ) : null}
      {props.type === "password" ? (
        <div className="relative">
          <input
            {...props}
            type={eyes ? "text" : "password"}
            className=" rounded-full border border-tech-gray px-7 py-4 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
          <div className="absolute inset-y-0 right-0 cursor-pointer pr-3 flex items-center ">
            {eyes ? (
              <EyesOpen onClick={() => setEyes(false)} />
            ) : (
              <EyesClosed onClick={() => setEyes(true)} />
            )}
          </div>
        </div>
      ) : (
        <input
          className={`rounded-full border border-tech-gray px-7 py-4 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
            label ? "mt-3" : ""
          }`}
          {...props}
        />
      )}
    </>
  );
};

export default InputField;

export const InputField2 = ({ label, ...props }) => {
  const [eyes, setEyes] = useState(false);
  return (
    <div>
      {label ? (
        <label
          htmlFor={props.id}
          className=" text-sm text-[#474747] ml-[5%] font-[600] tracking-wider"
        >
          {label}
        </label>
      ) : null}
      {props.type === "password" ? (
        <div className="relative">
          <input
            {...props}
            type={eyes ? "text" : "password"}
            className="bg-[#F5F5F5] rounded-3xl px-7 py-2 w-full outline-none disabled:opacity-50"
            id={props.id}
          />
          <div className="absolute inset-y-0 right-0 cursor-pointer pr-3 flex items-center ">
            {eyes ? (
              <EyesOpen onClick={() => setEyes(false)} />
            ) : (
              <EyesClosed onClick={() => setEyes(true)} />
            )}
          </div>
        </div>
      ) : (
        <input
          id={props.id}
          className="bg-[#F5F5F5] rounded-3xl px-7 py-2 w-full outline-none disabled:opacity-50"
          {...props}
        />
      )}
    </div>
  );
};

//generic input
export const GenericInputText = function ({
  placeholder,
  title,
  required,
  name,
  type,
  text,
  setText,
  handleChange,
  readOnly,
  max,
  min,
}) {
  return (
    <div className="w-full">
      {title && (
        <label
          htmlFor={name}
          className="text-form-label font-semibold mb-3 block lg:text-lg"
        >
          {title}
        </label>
      )}
      <input
        type={type ?? "text"}
        placeholder={placeholder}
        className="w-full lg:w-[98%] px-6 py-4 rounded-full placeholder:placeholder-filter-text placeholder:text-base placeholder:font-light border border-[#C7C7C7] focus:border-text-fundamental bg-white text-lg outline-none"
        required={required}
        name={name}
        value={text}
        onChange={
          handleChange
            ? handleChange
            : (e) => {
                if (max && type === "text") {
                  if (e.target.value.length <= max) {
                    setText(e.target.value);
                  } else {
                    toast.error("75 max");
                  }
                } else {
                  setText(e.target.value);
                }
              }
        }
        readOnly={readOnly}
        min={min}
        max={max}
      />
    </div>
  );
};

export const GenericInputFile = function ({ id, file, setFile }) {
  const { handleFileUpload } = useSingleFileUpload(setFile, file);
  return (
    <div className="w-full">
      <div className="flex justify-between items-center w-full lg:w-[98%] p-2 rounded-full placeholder:placeholder-filter-text placeholder:text-base placeholder:font-light border border-[#C7C7C7] focus:border-text-fundamental bg-white text-lg outline-none ">
        <p className=" pl-4 text-[#7C7C7C]">
          {file.fileLoading
            ? "Uploading...."
            : file.file_name
            ? file.file_name
            : "Attach File"}
        </p>
        <label
          htmlFor={id}
          className="w-max block cursor-pointer rounded-full bg-[#100E86] text-white font-bold py-3 px-16 mon-hover"
        >
          Browse
        </label>
      </div>

      <input type={"file"} id={id} hidden onChange={handleFileUpload} />
    </div>
  );
};

export const GenericDownloadFile = function ({ id, file }) {
  return (
    <div className="w-full">
      <div className="flex justify-between items-center w-full lg:w-[98%] p-2 rounded-full placeholder:placeholder-filter-text placeholder:text-base placeholder:font-light border border-[#C7C7C7] focus:border-text-fundamental bg-white text-lg outline-none ">
        <p className=" pl-4 text-[#7C7C7C]">{file.file_name}</p>
        <a
          href={file.uploadedUrl}
          target="_blank"
          rel="noreferrer"
          className="w-max block cursor-pointer rounded-full bg-[#100E86] text-white font-bold py-3 px-16 mon-hover"
        >
          Open
        </a>
      </div>
    </div>
  );
};

export const GenericSelect = function ({
  placeholder,
  title,
  required,
  name,
  handleChange,
  value,
  children,
}) {
  return (
    <div className="w-full cursor-pointer">
      {title && (
        <label
          htmlFor={name}
          className="text-form-label font-semibold mb-3 block lg:text-lg"
        >
          {title}
        </label>
      )}
      <div className="relative">
        <div className="absolute right-10 top-[calc(50%-4.5px)]">
          <ArrowDown fill={"#263238"} />
        </div>

        <select
          placeholder={placeholder}
          className=" appearance-none w-full lg:w-[98%] px-6 py-4 rounded-full placeholder:placeholder-filter-text placeholder:text-base placeholder:font-light border border-[#C7C7C7] focus:border-text-fundamental bg-white text-lg outline-none"
          required={required}
          name={name}
          value={value}
          onChange={handleChange}
        >
          {children}
        </select>
      </div>
    </div>
  );
};

//generic input
export const GenericInputLink = function ({
  placeholder,
  title,
  required,
  name,
  type,
  text,
  setText,
  handleChange,
}) {
  const [divActive, setDivActive] = useState(false);

  return (
    <div className="w-full">
      {title && (
        <label
          htmlFor={name}
          className="text-form-label font-semibold mb-3 block lg:text-lg"
        >
          {title}
        </label>
      )}
      <div
        className={`bg-white border ${
          divActive ? "border-text-fundamental " : "border-[#C7C7C7] "
        }  rounded-full flex items-center w-full lg:w-[98%] outline-none`}
      >
        <div className="w-[3rem] flex items-center justify-center ">
          <LinkIcon />
        </div>
        <input
          type={type ?? "text"}
          placeholder={placeholder}
          className=" w-[calc(100%-3rem)] pr-6 py-4 rounded-full placeholder:placeholder-filter-text placeholder:text-base placeholder:font-light   bg-white text-lg outline-none"
          required={required}
          name={name}
          value={text}
          onFocus={() => {
            setDivActive(true);
          }}
          onBlur={() => {
            setDivActive(false);
          }}
          onChange={
            handleChange ? handleChange : (e) => setText(e.target.value)
          }
        />
      </div>
    </div>
  );
};

export const GenericInputTextArea = ({
  placeholder,
  title,
  required,
  name,
  text,
  setText,
  handleChange,
}) => {
  return (
    <>
      <label
        htmlFor={name}
        className="text-form-label font-semibold mb-3 block lg:text-lg"
      >
        {title}
      </label>
      <textarea
        rows={8}
        type="text"
        placeholder={placeholder}
        className="w-full lg:w-[98%] px-6 py-4 rounded-xl placeholder:placeholder-filter-text placeholder:text-base placeholder:font-light border border-[#C7C7C7] focus:border-text-fundamental bg-white text-lg outline-none resize-none"
        required={required}
        name={name}
        value={text}
        onChange={handleChange ? handleChange : (e) => setText(e.target.value)}
      />
    </>
  );
};

export const GenericRichTextArea = ({
  placeholder,
  title,
  name,
  editorState,
  setEditorState,
}) => {
  return (
    <>
      <label
        htmlFor={name}
        className="text-form-label font-semibold mb-3 block lg:text-lg"
      >
        {title}
      </label>
      <div className="w-full lg:w-[98%] px-6 py-4 rounded-xl placeholder:placeholder-filter-text placeholder:text-base placeholder:font-light border border-[#C7C7C7] focus:border-text-fundamental bg-white text-lg outline-none ">
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          editorClassName="min-h-[40vh]"
          stripPastedStyles={true}
          placeholder={placeholder}
          toolbar={{
            fontFamily: {
              options: [
                "Ubuntu",
                "DM Sans",
                "Nunito",
                "Inter",
                "Open Sans",
                "Poppins",
              ],
            },
          }}
        />
      </div>
    </>
  );
};
// focus:outline-text-fundamental/75

export const GenericInputDateComponent = (props) => {
  return (
    <>
      <aside className="lg:w-[30rem]">
        <label
          htmlFor={props.id}
          className="text-form-label font-semibold mb-3 block"
        >
          {props.title}
        </label>
        <div className="flex items-center bg-[#EBEBEB] shadow-sm rounded-full px-6 py-4 relative">
          <input
            type="date"
            className=" block w-full placeholder:placeholder-filter-text/70 placeholder:text-base placeholder:font-normal outline-none text-lg bg-transparent"
            {...props}
          />
        </div>
      </aside>
    </>
  );
};

export const GenericSliderRange = ({
  placeholder,
  title,
  required,
  name,
  value,
  handleChange,
  min,
  max,
}) => {
  const percentageValue = (Number(value) / max) * 100;
  return (
    <>
      <label
        htmlFor={name}
        className="text-form-label font-semibold mb-3 block lg:text-lg"
      >
        {title}
      </label>
      <div className="flex justify-between px-2">
        <div className="flex gap-x-5 items-center w-[87%]">
          <p>{min}</p>
          <input
            style={{
              background: `linear-gradient(to right, #8C8BCC ${percentageValue}%, #E0E7FF ${percentageValue}%)`,
            }}
            type="range"
            min={min}
            max={max}
            className="slider"
            id="myRange"
            placeholder={placeholder}
            // className="w-full lg:w-[98%] px-6 py-4 rounded-full placeholder:placeholder-filter-text placeholder:text-base placeholder:font-light border border-[#C7C7C7] focus:border-text-fundamental bg-white text-lg outline-none"
            required={required}
            name={name}
            value={value}
            onChange={handleChange}
          />
          <p>{max}</p>
        </div>
        <div className=" lg:text-lg gap-x-2 flex items-center">
          <input
            name={name}
            type="number"
            className=" rounded-[10px] border border-[#C7C7C7] w-11 h-12 text-center outline-none lg:text-lg"
            value={value}
            onChange={handleChange}
            min={min}
            max={max}
          />
          /{max}
        </div>
      </div>
    </>
  );
};

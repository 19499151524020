import React from "react";
import { useNavigate } from "react-router-dom";

export const ArrowDown = (props) => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.15307 8.5325L0.75757 2.36525C0.12082 1.63963 0.63832 0.5 1.6047 0.5H12.3957C12.612 0.499815 12.8237 0.561974 13.0056 0.679034C13.1874 0.796093 13.3317 0.963092 13.4211 1.16003C13.5105 1.35697 13.5412 1.5755 13.5096 1.78945C13.478 2.0034 13.3853 2.20371 13.2428 2.36638L7.84732 8.53138C7.74173 8.65221 7.6115 8.74906 7.46539 8.81542C7.31928 8.88177 7.16067 8.9161 7.0002 8.9161C6.83972 8.9161 6.68111 8.88177 6.535 8.81542C6.38889 8.74906 6.25866 8.65221 6.15307 8.53138V8.5325Z"
        fill={props?.fill ? props.fill : "#474747"}
      />
    </svg>
  );
};

export const BackArrow = (props) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9987 6.00071H3.13972L6.76939 1.64111C6.93912 1.43691 7.02077 1.17365 6.99639 0.909247C6.97202 0.644844 6.8436 0.400955 6.63941 0.231232C6.43521 0.0615082 6.17195 -0.0201464 5.90755 0.00423112C5.64315 0.0286087 5.39925 0.157022 5.22953 0.36122L0.229979 6.36068C0.196343 6.4084 0.166264 6.45853 0.139987 6.51067C0.139987 6.56066 0.139988 6.59066 0.0699939 6.64066C0.0246714 6.75531 0.000941037 6.87735 0 7.00063C0.000941037 7.1239 0.0246714 7.24594 0.0699939 7.36059C0.0699939 7.41059 0.0699936 7.44059 0.139987 7.49058C0.166264 7.54272 0.196343 7.59285 0.229979 7.64057L5.22953 13.64C5.32355 13.7529 5.44128 13.8437 5.57435 13.9059C5.70742 13.9681 5.85257 14.0002 5.99946 14C6.23309 14.0005 6.45951 13.9191 6.63941 13.77C6.74066 13.6861 6.82435 13.583 6.8857 13.4666C6.94704 13.3503 6.98484 13.223 6.99691 13.0921C7.00899 12.9611 6.9951 12.829 6.95606 12.7034C6.91702 12.5778 6.85359 12.4612 6.76939 12.3601L3.13972 8.00054H14.9987C15.2639 8.00054 15.5182 7.89519 15.7057 7.70767C15.8932 7.52015 15.9986 7.26582 15.9986 7.00063C15.9986 6.73543 15.8932 6.4811 15.7057 6.29358C15.5182 6.10606 15.2639 6.00071 14.9987 6.00071Z"
        fill={props.color ? props.color : "black"}
      />
    </svg>
  );
};
export const GoBack = () => {
  const navigate = useNavigate();
  return (
    <div className=" cursor-pointer" onClick={() => navigate(-1)}>
      <BackArrow />
    </div>
  );
};

export const ArrowDown2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2998 9.7L8.69977 12.3C8.3831 12.6167 8.31243 12.979 8.48777 13.387C8.66243 13.7957 8.97477 14 9.42477 14H14.5748C15.0248 14 15.3371 13.7957 15.5118 13.387C15.6871 12.979 15.6164 12.6167 15.2998 12.3L12.6998 9.7C12.5998 9.6 12.4914 9.525 12.3748 9.475C12.2581 9.425 12.1331 9.4 11.9998 9.4C11.8664 9.4 11.7414 9.425 11.6248 9.475C11.5081 9.525 11.3998 9.6 11.2998 9.7Z"
        fill="#263238"
      />
    </svg>
  );
};

export const ArrowLeft = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.6 12L8 10.6L3.4 6L8 1.4L6.6 0L0.6 6L6.6 12Z" fill="white" />
    </svg>
  );
};

export const ArrowRight = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.4 12L0 10.6L4.6 6L0 1.4L1.4 0L7.4 6L1.4 12Z" fill="white" />
    </svg>
  );
};

export const ArrowRight2 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8839 10.8839C19.372 10.3957 19.372 9.60427 18.8839 9.11612L10.9289 1.16117C10.4408 0.67301 9.64932 0.67301 9.16117 1.16117C8.67301 1.64932 8.67301 2.44078 9.16117 2.92893L16.2322 10L9.16117 17.0711C8.67301 17.5592 8.67301 18.3507 9.16117 18.8388C9.64932 19.327 10.4408 19.327 10.9289 18.8388L18.8839 10.8839ZM0 11.25H18V8.75H0V11.25Z"
        fill="#292929"
      />
    </svg>
  );
};

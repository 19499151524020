// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { VAPID_KEY } from "../utils/constants";
import { Api } from "../api/Api";
import { toast } from "react-hot-toast";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCr1cTG7ytNeKZuyQTD6QtnnzrwRlmM4RU",
  authDomain: "community-app-913ab.firebaseapp.com",
  projectId: "community-app-913ab",
  storageBucket: "community-app-913ab.appspot.com",
  messagingSenderId: "656283800623",
  appId: "1:656283800623:web:c65d111e7dd98a6e6422da",
  measurementId: "G-F5NNFC1H6T",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const callMessagingToken = async () => {
  console.log("Requesting permission...");
  return Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapidKey: VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            Api.notification.updateFcmToken({ token: currentToken });
            return currentToken;
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            return null;
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          return null;
        });
    }
    return null;
  });
};

onMessage(messaging, (payload) => {
  toast.success(payload.notification.title);
});

export const initiateTokenRequest = async (id) => {
  const fcmToken = localStorage.getItem("talntsAppFcmToken");
  const fcmTokenUser = localStorage.getItem("talntsAppFcmTokenUser");

  if (fcmToken === null || fcmTokenUser !== id) {
    const token = await callMessagingToken();
    if (token !== null) {
      localStorage.setItem("talntsAppFcmToken", token);
      localStorage.setItem("talntsAppFcmTokenUser", id);
    }
  }
};

import toast from "react-hot-toast";
import { v4 } from "uuid";
import { Api } from "../api/Api";

const sizeInKilobytes = (size) => {
  return `${Math.round(size / 1000)} KB`;
};

export const useSingleFileUpload = (setFile, stateFile) => {
  const handleCloudUpload = async (file) => {
    let payload = new FormData();
    payload.append("file", file);
    payload.append("filetype", "image");
    try {
      const response = await Api.data.uploadFile(payload);
      setFile((item) => ({
        ...item,
        uploadedUrl: response.data?.url,
        fileLoading: false,
      }));
    } catch (err) {
      setFile({
        ...stateFile,
        fileLoading: false,
      });
      toast.error("File upload failed");
    }
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    const inputFile = files[0];
    if (inputFile.size > 1000000) return toast.error("File is larger than 1mb");
    const fileUrl = URL.createObjectURL(inputFile);
    const newFile = {
      file: inputFile,
      fileUrl: fileUrl,
      size: sizeInKilobytes(inputFile.size),
      file_name: inputFile.name,
      id: v4(),
      fileLoading: true,
      uploadedUrl: "",
    };
    setFile(newFile);
    await handleCloudUpload(newFile.file, newFile.id);
  };
  return { handleFileUpload };
};

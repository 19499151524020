import React from "react";

const SalesIcon = ({ fill }) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.5C0 1.84531 1.34531 0.5 3 0.5H21C22.6547 0.5 24 1.84531 24 3.5V18.5C24 20.1547 22.6547 21.5 21 21.5H3C1.34531 21.5 0 20.1547 0 18.5V3.5ZM3 6.5V18.5H10.5V6.5H3ZM21 6.5H13.5V18.5H21V6.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default SalesIcon;

import React from "react";
import { TailSpin } from "react-loader-spinner";
import RotatingLogo from "../../assets/icons/rotating-logo";

const Loader = () => {
  return (
    <div className="w-full h-full flex items-center justify-center mt-6">
      <TailSpin width={40} height={40} color="#00B4D7" />
    </div>
  );
};

export default Loader;

export const Spinner = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <TailSpin width={30} height={30} color="#00B4D7" />
    </div>
  );
};

export const PageLoader = ({ notLoading }) => {
  if (notLoading) {
    return null;
  }
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-40 flex items-center justify-center bg-black bg-opacity-10">
      <RotatingLogo />
    </div>
  );
};

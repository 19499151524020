export const Auth = {
  getToken: () => {
    const user = JSON.parse(localStorage.getItem("recoil-persist") || "null");
    if (user) {
      const token = user["user-login"]?.access;
      return token;
    } else {
      return null;
    }
  },
};

import { ReactComponent as Discord } from "../assets/svgs/discord.svg";
import { ReactComponent as Whatsapp } from "../assets/svgs/whatsapp.svg";
import { ReactComponent as Slack } from "../assets/svgs/slack.svg";
import { ReactComponent as Telegram } from "../assets/svgs/telegram.svg";
import TextIcon from "../assets/icons/course-content-types/text";
import VideoIcon from "../assets/icons/course-content-types/video";
import LiveClassIcon from "../assets/icons/course-content-types/live-class";
import AssignmentIcon from "../assets/icons/course-content-types/assignment";
export const USER_API_URL = process.env.REACT_APP_USER_API_URL;
export const COMMUNITIES_API_URL = process.env.REACT_APP_COMMUNITIES_API_URL;
export const COMMERCE_API_URL = process.env.REACT_APP_COMMERCE_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const PAYSTACK_PUBLIC_KEY = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
export const FLUTTERWAVE_PUBLIC_KEY =
  process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY;
export const VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

export const EMAIL_NOT_VERIFIED_ERROR =
  "Account not activated, Please verify your email";

export const communityStatuses = {
  open_community: "Open Community",
  closed_community: "Closed Community",
};

export const communityPlatforms = {
  whatsapp: "Whatsapp",
  telegram: "Telegram",
  discord: "Discord",
  slack: "Slack",
};

//================collectibles for the search bar on the communities page both for th top bar and the communities filter bar===
export const platform = [
  {
    name: "Platform",
    value: "",
    style: { fontSize: ".8rem", fontWeight: 700 },
  },
  { name: "Whatsapp", value: "whatsapp" },
  { name: "Telegram", value: "telegram" },
  { name: "Slack", value: "slack" },
  { name: "Discord", value: "discord" },
];
export const groups = [
  //{
  //  name: "Groups",
  //  value: "",
  //  disabled: true,
  //  style: { fontSize: ".8rem", fontWeight: 700 },
  //},
  { name: "Communities", value: "communities", disabled: false },
];
export const status = [
  {
    name: "Status",
    value: "",
    disabled: true,
    style: { fontSize: ".8rem", fontWeight: 700 },
  },
  { name: "Open ", value: "open_community", disabled: false },
  { name: "Closed ", value: "closed_community", disabled: false },
];
//============================================================================================

export const platformsImg = {
  whatsapp: <Whatsapp />,
  telegram: <Telegram />,
  discord: <Discord />,
  slack: <Slack />,
};

export const contentTypes = {
  text: {
    icon: <TextIcon />,
    type: "Add Text",
    helpText: "Add your own text",
  },
  video: {
    icon: <VideoIcon />,
    type: "Add Video",
    helpText: "Embed a Video from YouTube or Vimeo",
  },
  live_class: {
    icon: <LiveClassIcon />,
    type: "Live Class",
    helpText: "Schedule a live class",
  },
  assignment: {
    icon: <AssignmentIcon />,
    type: "Assignment",
    helpText: "Add Assignment",
  },
};

export const fileNameFromFirebaseURl = (in_url) => {
  const url = String(in_url);
  if (url.includes("appspot.com/")) {
    const items = url.split("appspot.com/");
    return items[items.length - 1];
  } else {
    return "";
  }
};

import React from "react";

const BellIcon = ({ props }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5174_3964)">
        <path
          d="M20.0749 17.1918L19.8671 17.0084C19.2776 16.4832 18.7617 15.881 18.3332 15.2179C17.8652 14.3028 17.5848 13.3034 17.5082 12.2784V9.25955C17.5123 7.64964 16.9283 6.09367 15.866 4.88398C14.8037 3.67428 13.3363 2.89413 11.7393 2.6901V1.90177C11.7393 1.6854 11.6534 1.47789 11.5004 1.32489C11.3474 1.17189 11.1399 1.08594 10.9235 1.08594C10.7071 1.08594 10.4996 1.17189 10.3466 1.32489C10.1936 1.47789 10.1077 1.6854 10.1077 1.90177V2.70233C8.52504 2.92106 7.07531 3.70593 6.02698 4.91156C4.97866 6.11719 4.40277 7.66189 4.40599 9.25955V12.2784C4.32945 13.3034 4.04897 14.3028 3.58099 15.2179C3.15986 15.8793 2.65222 16.4815 2.07155 17.0084L1.86377 17.1918V18.9151H20.0749V17.1918Z"
          fill="#474747"
        />
        <path
          d="M9.3623 19.5547C9.41589 19.9421 9.60784 20.297 9.9027 20.5538C10.1976 20.8107 10.5754 20.9522 10.9665 20.9522C11.3575 20.9522 11.7354 20.8107 12.0302 20.5538C12.3251 20.297 12.517 19.9421 12.5706 19.5547H9.3623Z"
          fill="#474747"
        />
      </g>
      <defs>
        <clipPath id="clip0_5174_3964">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BellIcon;

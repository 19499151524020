import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useGetCommunitiesCategories } from "../../hooks/communities.hook";
import { arrayToObjectWithArray, searchFromArray } from "../../utils/utils";
import { ReactComponent as CancelIcon } from "../../assets/svgs/cancel.svg";
import { ArrowDown } from "../../assets/icons/arrow";

export default function CategoryFilter({
  selectedCategories,
  setSelectedCategories,
}) {
  const [searchedCategory, setSearchedCategory] = useState("");

  //===========gets all the categories===============//
  const { data } = useGetCommunitiesCategories();

  //===========search functionality for the categories page===============//
  const searchResult = searchFromArray(data?.data?.data, searchedCategory);

  //===========filters the categories in such a way that it is easy to get rendered===============//
  const res = arrayToObjectWithArray(searchResult);
  const categoriesHeader = Object.keys(res);

  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleCategorySelect = (e, id) => {
    if (e.target.checked) {
      setSelectedCategories((selected) => [...selected, id]);
    } else {
      setSelectedCategories((selected) =>
        selected.filter((item) => item !== id)
      );
    }
  };

  return (
    <>
      <div className="flex items-center gap-4 justify-center mt-1">
        <button
          type="button"
          onClick={openModal}
          className="flex justify-between items-center relative cursor-pointer w-[7rem] text-left rounded-full py-2 px-2 border border-filter text-filter-text text-xs sm:text-[.7rem] "
        >
          All Categories
          <ArrowDown className="w-[0.5rem]" />{" "}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[21]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full min-h-[30rem] max-w-md lg:max-w-[60rem] transform overflow-hidden rounded-2xl bg-white p-6 px-4 lg:px-12 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-gray-900 flex justify-between items-center"
                  >
                    <span>Categories</span>
                    <CancelIcon
                      className="cursor-pointer"
                      onClick={() => setIsOpen(false)}
                    />
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Search or select from the categories below to filter by:
                    </p>
                  </div>
                  <div className="mt-3">
                    <input
                      type="text"
                      placeholder="Search Categories"
                      className="w-full border-filter border placeholder:text-filter-text rounded-full px-4 py-2 outline-tech-blue1"
                      onChange={(e) => setSearchedCategory(e.target.value)}
                      value={searchedCategory}
                    />
                  </div>

                  <div className="mt-8 flex gap-2">
                    <input
                      type="checkbox"
                      id="all"
                      className="accent-tech-blue1 cursor-pointer"
                      checked={selectedCategories.length === 0}
                      onClick={() => setSelectedCategories([])}
                      onChange={() => {}}
                    />
                    <label htmlFor="all">All Categories</label>
                  </div>
                  <aside className="mt-2 flex gap-4 justify-between flex-wrap">
                    {categoriesHeader.map((category_header, id) => (
                      <Fragment key={id}>
                        <section>
                          <h2 className="font-semibold">
                            {category_header[0].toUpperCase()}
                            {category_header.slice(1)}
                          </h2>
                          {res[category_header].map((category) => (
                            <div
                              key={category.id}
                              className="flex items-center gap-2 mt-3 cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                name={category.slug}
                                id={category.id}
                                className="accent-tech-blue1 cursor-pointer"
                                checked={selectedCategories.includes(
                                  category.id
                                )}
                                onChange={(e) =>
                                  handleCategorySelect(e, category.id)
                                }
                              />
                              <label
                                htmlFor={category.id}
                                className="cursor-pointer"
                              >
                                {category.name}
                              </label>
                            </div>
                          ))}
                        </section>
                      </Fragment>
                    ))}
                  </aside>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import moment from "moment";
import { toast } from "react-hot-toast";
import jwt_decode from "jwt-decode";

export const objectToQueries = (obj) => {
  let outputString = "";
  let keyArray = Object.keys(obj);
  keyArray.forEach((key, index) => {
    if (obj[key]) {
      outputString += `${key}=${obj[key]}&`;
    }
  });

  return outputString.slice(0, -1);
};

export const arrayToObjectWithArray = (arr) => {
  const res = {};

  arr?.forEach((cnt) => {
    if (res[cnt.type]) {
      return (res[cnt.type] = [...res[cnt.type], cnt]);
    }
    res[cnt.type] = [cnt];
  });
  return res;
};

export const objectContainsValues = (obj) => {
  for (let key in obj) {
    if (obj[key]) {
      return true;
    }
  }
  return false;
};

export const searchFromArray = (arr, searchedValue) => {
  let res = [];
  for (let ele in arr) {
    let str = arr[ele].name.toLowerCase().split(" ").join("");
    const pattern = new RegExp(
      searchedValue.toLowerCase().replace(/\s+/g, ""),
      "gi"
    );
    if (pattern.test(str)) {
      res.push(arr[ele]);
    }
  }
  return res;
};

export const handleToastError = (errorMessage, key = null) => {
  if (Array.isArray(errorMessage)) {
    for (let item of errorMessage) {
      handleToastError(item, key);
    }
  } else if (typeof errorMessage === "object") {
    for (let key1 in errorMessage) {
      handleToastError(errorMessage[key1], key1);
    }
  } else {
    if (key !== null) {
      toast.error(`${key}: ${errorMessage}`);
    } else {
      toast.error(errorMessage);
    }
  }
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const timeDiffernceToString = (created_at) => {
  const currentTime = new Date();
  const timestamp = new Date(created_at);
  const timeDifference = currentTime - timestamp;
  const minutes = Math.floor(timeDifference / (1000 * 60));
  if (minutes < 1) {
    return "Just now";
  } else if (minutes === 1) {
    return "1 minute ago";
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (minutes < 1440) {
    const hours = Math.floor(minutes / 60);
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else {
    const days = Math.floor(minutes / 1440);
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  }
};

export const getUtcDateAndTimeStrings = (date_string, time_string) => {
  const combinedDateTimeString = `${date_string}T${time_string}`;

  // Create a moment object using the combined date and time string
  const utcDateTime = moment(combinedDateTimeString).local().utc();

  // Format UTC date and time strings
  const utcDateString = utcDateTime.format("YYYY-MM-DD");
  const utcTimeString = utcDateTime.format("HH:mm:ss");

  return {
    utcDateString,
    utcTimeString,
  };
};

export const getLocalDateAndTimeStringsFromUtc = (
  utcDateString,
  utcTimeString
) => {
  const combinedDateTimeString = `${utcDateString}T${utcTimeString}`;

  // Create a moment object using the combined UTC date and time string
  const utcDateTime = moment.utc(combinedDateTimeString);

  // Convert UTC moment to local moment
  const localDateTime = utcDateTime.local();

  // Format local date and time strings
  const localDateString = localDateTime.format("YYYY-MM-DD");
  const localTimeString = localDateTime.format("HH:mm:ss");

  return {
    localDateString,
    localTimeString,
  };
};

export const cachePreLocation = () => {
  const currentLocation = window.location.pathname;
  localStorage.setItem("preLocation", currentLocation);
};

export const handle401 = () => {
  cachePreLocation();
  window.location.href = "/login";
};

export const checkJwtExpiry = (token) => {
  const exp = jwt_decode(token).exp;
  if (Date.now() >= exp * 1000) {
    return false;
  }
  return true;
};

export const routeChecker = () => {
  const currentLocation = window.location.pathname;
  // const viewBootcampPattern = /^\\/view-bootcamp\/[a-zA-Z0-9-]+$/;
  // const explorePattern = /^\\/explore$/;
  // return (
  //   viewBootcampPattern.test(currentLocation) ||
  //   explorePattern.test(currentLocation)
  // );

  return (
    currentLocation.includes("explore") ||
    currentLocation.includes("view-bootcamp") ||
    currentLocation.includes("/verify/")
  );
};

export function getEmbedUrl(url) {
  if (url.includes("youtu")) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    const id = match && match[2].length === 11 ? match[2] : null;
    if (id) {
      return `https://www.youtube.com/embed/${id}`;
    } else {
      return null;
    }
  } else if (url.includes("vimeo.com/")) {
    if (url.includes("player.vimeo.com")) {
      return url;
    }
    let idIndex = 3;
    let hIndex = 4;

    if (!url.includes("http")) {
      idIndex = 1;
      hIndex = 2;
    }

    const id = url.split("/")[idIndex];
    const h = url.split("/")[hIndex];

    if (id) {
      if (h) {
        const newH = h.split("?")[0];
        return `https://player.vimeo.com/video/${id}?h=${newH}`;
      } else {
        return `https://player.vimeo.com/video/${id}`;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function isValidUrl(url) {
  try {
    new URL(url);
    if (getEmbedUrl(url)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export const weeksFromDateRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const weeks = (end - start) / (1000 * 7 * 24 * 60 * 60);
  const completeWeeks = Math.floor(weeks);
  const remainingDays = Math.ceil(
    (end - start) / (1000 * 24 * 60 * 60) - completeWeeks * 7
  );
  return { completeWeeks, remainingDays };
};

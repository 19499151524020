import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import userAtom from "../../atoms/user.atom";
import { Auth } from "../../utils/storage";
import Loader from "../loader";
import { checkJwtExpiry, handle401 } from "../../utils/utils";

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(false);
  const userData = useRecoilValue(userAtom);
  const checkToken = async () => {
    const token = Auth.getToken();
    if (token) {
      if (checkJwtExpiry(token)) {
        setAuth(true);
        if (!userData?.user?.registration_complete) {
          toast.error("Please complete profile");
          navigate("/complete-registration");
        } else {
          const preLocation = localStorage.getItem("preLocation");
          if (preLocation) {
            localStorage.removeItem("preLocation");
            navigate(preLocation);
          } 
        }
      } else {
        setAuth(false);
        handle401();
      }
    } else {
      setAuth(false);
      handle401();
    }
  };

  useEffect(() => {
    checkToken();
  }, []); // eslint-disable-line
  return <>{auth ? <Outlet /> : <Loader />}</>;
};

export default ProtectedRoutes;

export const ProtectedSellerRoutes = () => {
  const userData = useRecoilValue(userAtom);
  return (
    <>
      {userData.user.seller ? <Outlet /> : <Navigate to={"/become-seller"} />}
    </>
  );
};

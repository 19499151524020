import React from "react";
import LogoDark from "../../assets/icons/logo";

import PeopleIcon from "../../assets/icons/people";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { PurchaseIcon, ExploreIcon } from "../../assets/assets.exports";
import { useRecoilValue } from "recoil";
import userAtom from "../../atoms/user.atom";
import SalesIcon from "../../assets/icons/sales";
import DashboardIcon from "../../assets/icons/dashboard";
import ProductsIcon from "../../assets/icons/products";
import CustomersIcon from "../../assets/icons/customers";
import SettingsIcon from "../../assets/icons/settings";

const SideBarItems = ({ anon, enableLoginModal, setSidebarOpen }) => {
  const loc = useLocation();

  const navigate = useNavigate();

  const user = useRecoilValue(userAtom);

  const checkActive = (path) => {
    if (loc.pathname.startsWith(path)) {
      return true;
    }
    return false;
  };

  let items = [
    {
      name: "Social",
      options: [
        {
          name: "Communities",
          icon: PeopleIcon,
          path: anon ? false : "/communities",
        },
      ],
    },

    {
      name: "Learning",
      options: [
        {
          name: "Dashboard",
          icon: DashboardIcon,
          path: anon ? false : "/buyer-dashboard",
        },
        {
          name: "Explore",
          icon: ExploreIcon,
          path: "/explore",
        },
        {
          name: "Purchases",
          icon: PurchaseIcon,
          path: anon ? false : "/purchases",
        },
      ],
    },
    {
      name: "Sell",
      options: [
        {
          name: "Dashboard",
          icon: DashboardIcon,
          path: "/seller-dashboard",
        },
        {
          name: "Sales",
          icon: SalesIcon,
          path: "/sales",
        },
        {
          name: "Products",
          icon: ProductsIcon,
          path: "/products",
        },
        {
          name: "Customers",
          icon: CustomersIcon,
          path: "/customers",
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex justify-center flex-col items-center flex-shrink-0 px-4 py-4">
        <LogoDark />
        <div className="bg-[#A2A0FF] rounded-[15px] text-white py-1 px-4 text-sm mt-3">
          BETA
        </div>
      </div>
      <div className="mt-5 flex-1 flex flex-col">
        <nav className="flex-1 px-6 pb-4 space-y-2 xl:space-y-4 py-4">
          {items.map((item, index) => {
            if (item.name === "Sell" && !user.user.seller) {
              return <div key={index}></div>;
            }
            return (
              <div key={index} className="mb-10">
                <p className="font-[600] text-[#100E86] pb-5">{item.name}</p>
                <div className=" space-y-0 xl:space-y-1">
                  {item.options.map((option, idx) => (
                    <div key={idx}>
                      {option.path ? (
                        <Link
                          to={option.path}
                          className=" flex items-center gap-6 rounded-[25px]  px-6  w-full py-2"
                          style={{
                            color: checkActive(option.path)
                              ? "#100E86"
                              : "#7C7C7C",
                            backgroundColor: checkActive(option.path)
                              ? "rgba(16, 14, 134, 0.1)"
                              : "#ffffff",
                          }}
                        >
                          <div className="flex-[1]">
                            <option.icon
                              fill={
                                checkActive(option.path) ? "#100E86" : "#7C7C7C"
                              }
                            />
                          </div>
                          <p className="font-[600] xl:text-lg flex-[4]">
                            {option.name}
                          </p>
                        </Link>
                      ) : (
                        <div
                          onClick={() => {
                            enableLoginModal();
                            setSidebarOpen && setSidebarOpen(false);
                          }}
                          className=" flex items-center gap-6 rounded-[25px]  px-6  w-full py-2"
                          style={{
                            color: "#7C7C7C",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <div className="flex-[1]">
                            <option.icon fill={"#7C7C7C"} />
                          </div>
                          <p className="font-[600] xl:text-lg flex-[4]">
                            {option.name}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
          {/* <div
            className=" flex items-center gap-6 rounded-[25px] w-max px-6 pt-2 cursor-pointer text-[#7C7C7C]"
            onClick={logout}
          >
            <div className=" min-w-[1rem]">
              <LogoutIcon stroke={"#7C7C7C"} />{" "}
            </div>
            <p className="font-[600] xl:text-lg">{"Logout"}</p>
          </div> */}
        </nav>
      </div>
      <div className="px-6 pb-4 space-y-2 xl:space-y-4 py-4">
        {!anon ? (
          <Link
            to={"/settings"}
            className=" flex items-center gap-6 rounded-[25px]  px-6  w-full py-2"
            style={{
              color: checkActive("/settings") ? "#100E86" : "#474747",
              backgroundColor: checkActive("/settings")
                ? "rgba(16, 14, 134, 0.1)"
                : "#ffffff",
            }}
          >
            <div className="flex-[1]">
              <SettingsIcon
                fill={checkActive("/settings") ? "#100E86" : "#474747"}
              />
            </div>
            <p className="font-[600] xl:text-lg flex-[4]">Settings</p>
          </Link>
        ) : (
          <div
            onClick={enableLoginModal}
            className=" flex items-center gap-6 rounded-[25px]  px-6  w-full py-2"
            style={{
              color: "#474747",
              backgroundColor: "#ffffff",
            }}
          >
            <div className="flex-[1]">
              <SettingsIcon fill={"#474747"} />
            </div>
            <p className="font-[600] xl:text-lg flex-[4]">Settings</p>
          </div>
        )}
      </div>
      {user?.user?.seller ? null : (
        <button
          className="bg-[#3E3D88] text-sm xl:text-base text-white rounded-[25px] w-max font-bold px-9 py-3 mx-auto"
          onClick={anon ? enableLoginModal : () => navigate("/become-seller")}
        >
          Become a Seller
        </button>
      )}
    </>
  );
};

export default SideBarItems;

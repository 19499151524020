import { Fragment, Suspense, useEffect, useMemo, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import SearchIcon, { SearchIcon2 } from "../../assets/icons/search";

import { ArrowDown, BackArrow, GoBack } from "../../assets/icons/arrow";
import MenuIcon from "../../assets/icons/menu";
import SideBarItems from "./sidebar-items";

import { useGetFilteredCommunities } from "../../hooks/communities.hook";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { filteredCommunitiesAtom } from "../../atoms/communities.atom";

import { FilteredCommunityItem } from "../communities";
import userAtom from "../../atoms/user.atom";
import FilterSelect from "../filter-select";
import { groups, platform, status } from "../../utils/constants";

import { useNavigate, Outlet } from "react-router-dom";
import CategoryFilter from "../categories-filter";
import Modal from "../modal";
import BellIcon from "../../assets/icons/bell";
import { initiateTokenRequest } from "../../firebase";
import { useGetNotifications } from "../../hooks/notification.hook";
import { PageLoader } from "../loader";
import {
  routeChecker,
  checkJwtExpiry,
  cachePreLocation,
} from "../../utils/utils";
import { Auth } from "../../utils/storage";
import Loader from "../loader";
import { AuthButton } from "../button";
import InputField from "../input";
import GoogleButton from "../google";
import { useLogin } from "../../hooks/auth.hook";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PageLayout = (props) => {
  const checked = routeChecker();
  const [auth, setAuth] = useState(false);
  const [anon, setAnon] = useState(false);
  const checkAuth = async () => {
    const token = Auth.getToken();
    if (token) {
      if (checkJwtExpiry(token)) {
        setAuth(true);
      } else if (checked) {
        setAnon(true);
      }
    } else if (checked) {
      setAnon(true);
    }
  };
  useEffect(() => {
    checkAuth();
  }, []); // eslint-disable-line
  return (
    <>
      {auth ? (
        <LoggedInPageLayout {...props} />
      ) : anon ? (
        <AnonPageLayout {...props} />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PageLayout;

const LoggedInPageLayout = ({ children, header, back }) => {
  const navigate = useNavigate();
  const userData = useRecoilValue(userAtom);

  const clearUser = useResetRecoilState(userAtom);

  useEffect(() => {
    initiateTokenRequest(userData?.user?.user_id); // To get or set fcm token
  }, []); // eslint-disable-line
  const logout = () => {
    clearUser();
    navigate("/");
  };

  const [selectedGroup, setSelectedGroup] = useState(groups[0]);
  const [selectedPlatform, setSelectedPlatform] = useState(platform[0]);
  const [selectedStatus, setSelectedStatus] = useState(status[0]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  // const categories = [
  //   { name: "Categories", value: "", disabled: true },
  //   { name: "Communities", value: "communities", disabled: false },
  // ];

  // const [selectedCategories, setSelectedCategories] = useState(categories[0]);

  const [logoutModal, setLogoutModal] = useState(false);

  const handleCloseLogoutModal = () => {
    setLogoutModal(false);
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [searchOpen, setSearchOpen] = useState(false);

  const [filters, setFilters] = useState({});

  const [searchValue, setSearchValue] = useState("");

  const { data } = useGetNotifications();

  const newNotification = useMemo(
    () => data?.data?.data?.find((item) => item?.viewed === false),
    [data]
  );

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value) {
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
    }
  };

  const handleNotificationClick = () => {
    navigate("/notifications");
  };

  const handleProfileNavigation = (item) => {
    navigate("/profile");
    if (item === "Settings") {
      navigate("/settings");
    }
  };

  const filteredCommunities = useRecoilValue(filteredCommunitiesAtom);

  const { mutate, isLoading } = useGetFilteredCommunities();

  // useEffect(() => {
  //   // if (selectedStatus.value) {
  //   setFilters({ ...filters, status: selectedStatus.value });
  //   // }
  // }, [selectedStatus.value]);

  // useEffect(() => {
  //   // if (selectedPlatform.value) {
  //   setFilters({ ...filters, platform: selectedPlatform.value });
  //   // }
  // }, [selectedPlatform.value]);

  useEffect(() => {
    setFilters({
      ...filters,
      platform: selectedPlatform.value,
      status: selectedStatus.value,
    });
  }, [selectedPlatform.value, selectedStatus.value]); // eslint-disable-line

  useEffect(() => {
    mutate({ searchValue, filters, categories: selectedCategories });
  }, [searchValue, filters]); // eslint-disable-line

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <SideBarItems />
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64  md:flex-col md:fixed md:inset-y-0 ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow py-5  overflow-y-auto">
            <SideBarItems />
          </div>
        </div>
        <div className="md:ml-64 bg-[#F5F6FF] min-h-screen pt-3 relative">
          <div className="sticky top-0 z-10   md:mx-3 md:rounded-xl bg-white py-5 md:pt-2 md:pb-2  px-4 ">
            <div className="flex items-center justify-between">
              <button
                type="button"
                className="px-4  text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon aria-hidden="true" />
              </button>

              <div className="max-w-7xl hidden sm:flex items-center gap-x-4 flex-[2]">
                {back ? <GoBack /> : null}
                <h1 className=" text-lg hidden sm:block md:text-xl xl:text-2xl font-bold text-gray-900 whitespace-nowrap">
                  {searchOpen ? "Search Results" : header}
                </h1>
              </div>

              <div className="hidden lg:block flex-[2]">
                <div className=" bg-[#F6F6F6] rounded-[27.5px] flex items-center py-3 px-10 xl:py-4 xl:px-14 gap-x-2 w-max mx-auto">
                  <SearchIcon />
                  <input
                    type="text"
                    className=" w-36 bg-[#F6F6F6] text-xs xl:text-sm outline-none"
                    placeholder="Search talnts.app"
                    value={searchValue}
                    onChange={handleSearchValueChange}
                  />
                </div>
              </div>

              <div className="gap-x-3 flex items-center md:ml-6 flex-[2] justify-end">
                <div
                  className="lg:hidden"
                  onClick={() => setSearchOpen(!searchOpen)}
                >
                  <SearchIcon2 />
                </div>
                <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 relative"
                  onClick={handleNotificationClick}
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" />
                  {newNotification ? (
                    <div className="absolute h-2 w-2 rounded-full bg-red-500 top-0 right-0" />
                  ) : null}
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className=" relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none gap-x-1">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={userData?.user?.image_url}
                        alt=""
                      />
                      <ArrowDown />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={() =>
                              handleProfileNavigation("Your Profile")
                            }
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={() => handleProfileNavigation("Settings")}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Settings
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={() => setLogoutModal(true)}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Sign Out
                          </p>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>{" "}
            {searchOpen ? (
              <div className=" items-center gap-x-5 pt-6 hidden lg:flex">
                <FilterSelect
                  options={groups}
                  selected={selectedGroup}
                  setSelected={setSelectedGroup}
                />

                <div className=" h-12 border border-[#474747] w-0"></div>
                {selectedGroup.value === "communities" ? (
                  <>
                    <FilterSelect
                      options={platform}
                      selected={selectedPlatform}
                      setSelected={setSelectedPlatform}
                    />
                    <FilterSelect
                      options={status}
                      selected={selectedStatus}
                      setSelected={setSelectedStatus}
                    />
                    <CategoryFilter
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                    />
                  </>
                ) : null}
                {/* <SelectField
                  options={filters}
                  selected={selectedCategories}
                  setSelected={setSelectedCategories}
                /> */}
              </div>
            ) : null}
          </div>

          <div className="bg-[#F5F6FF] w-full p-3">
            {searchOpen ? (
              <>
                <div className="lg:hidden bg-white rounded-xl p-4 mb-3 space-y-3">
                  <div className="flex items-center gap-4">
                    <BackArrow onClick={() => setSearchOpen(false)} />
                    <p className=" font-bold">Search Results</p>
                  </div>
                  <div className=" bg-[#F6F6F6] rounded-[27.5px] flex items-center py-3 px-10 xl:py-4 xl:px-14 gap-x-2">
                    <SearchIcon />
                    <input
                      type="text"
                      className=" w-36 bg-[#F6F6F6] text-xs xl:text-sm outline-none"
                      placeholder="Search talnts.app"
                      value={searchValue}
                      onChange={handleSearchValueChange}
                    />
                  </div>

                  <div className=" items-center gap-x-5 flex  flex-wrap">
                    <div>
                      <FilterSelect
                        options={groups}
                        selected={selectedGroup}
                        setSelected={setSelectedGroup}
                      />
                    </div>

                    <div className=" h-12 border border-[#474747] w-0"></div>
                    {selectedGroup.value === "communities" ? (
                      <>
                        <FilterSelect
                          options={platform}
                          selected={selectedPlatform}
                          setSelected={setSelectedPlatform}
                        />
                        <FilterSelect
                          options={status}
                          selected={selectedStatus}
                          setSelected={setSelectedStatus}
                        />
                      </>
                    ) : null}
                    {/* <SelectField
                  options={filters}
                  selected={selectedCategories}
                  setSelected={setSelectedCategories}
                /> */}
                  </div>
                </div>
                {/* {selectedGroup.value === "communities" ? ( */}
                {/*{selectedGroup.value === "communities" ? (*/}
                <div className="min-h-[85vh] ">
                  <div className="bg-white rounded-3xl p-5">
                    <p className=" font-[600] pl-3 text-[#263238] pb-2">
                      Communities
                    </p>
                    <FilteredCommunityItem
                      community={filteredCommunities}
                      setSearchOpen={setSearchOpen}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                {/*) : (
                  <div className="w-full min-h-[85vh] flex items-center justify-center font-bold">
                    <p>Select a group to view</p>
                  </div>
                )}*/}
                {/*<div className="min-h-[85vh] ">
                  <div className="bg-white rounded-3xl p-5">
                    <p className=" font-[600] pl-3 text-[#263238] pb-2">
                      Communities
                    </p>

                    {isLoading ? (
                      <Loader />
                    ) : (
                      filteredCommunities.map((community, index) => (
                        <FilteredCommunityItem
                          community={community}
                          setSearchOpen={setSearchOpen}
                        />
                      ))
                    )}
                  </div>
                </div>*/}
              </>
            ) : (
              <Suspense fallback={<PageLoader />}>
                <Outlet />
              </Suspense>
            )}
          </div>
        </div>
        <Modal open={logoutModal} handleClose={handleCloseLogoutModal}>
          <div className=" bg-white rounded-xl w-[97vw] md:w-[50vw] lg:w-[30vw] p-4 text-center">
            <p className=" text-[#DD0101] font-bold">Logout</p>
            <p className=" pt-6 w-[80%] mx-auto">
              Are you sure you want to logout?
            </p>

            <div className=" pt-6 flex gap-10 mx-auto w-max">
              <button
                className="bg-white rounded-[1.25rem] py-3 px-12 text-[#100E86] font-bold text-xs"
                onClick={() => logout()}
              >
                Yes
              </button>
              <button
                className="bg-[#100E86] rounded-[1.25rem] py-3 px-12 text-white font-bold text-xs"
                onClick={() => handleCloseLogoutModal()}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

const AnonPageLayout = ({ children, header, back }) => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const { mutate, isLoading } = useLogin();
  const enableLoginModal = () => {
    setLoginModal(true);
  };
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };
  const handleCloseLoginModal = () => {
    setLoginModal(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(loginData, {
      onSuccess: () => {
        window.location.reload();
      },
    });
  };
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <SideBarItems
                  anon
                  enableLoginModal={enableLoginModal}
                  setSidebarOpen={setSidebarOpen}
                />
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64  md:flex-col md:fixed md:inset-y-0 ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow py-5  overflow-y-auto">
            <SideBarItems anon enableLoginModal={enableLoginModal} />
          </div>
        </div>
        <div className="md:ml-64 bg-[#F5F6FF] min-h-screen pt-3 relative">
          <div className="sticky top-0 z-10   md:mx-3 md:rounded-xl bg-white py-5 md:pt-2 md:pb-2  px-4 ">
            <div className="flex items-center justify-between">
              <button
                type="button"
                className="px-4  text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon aria-hidden="true" />
              </button>

              <div className="max-w-7xl hidden sm:flex items-center gap-x-4 flex-[2]">
                {back ? <GoBack /> : null}
                <h1 className=" text-lg hidden sm:block md:text-xl xl:text-2xl font-bold text-gray-900 whitespace-nowrap">
                  {header}
                </h1>
              </div>

              <div className="hidden lg:block flex-[2]">
                <div className=" bg-[#F6F6F6] rounded-[27.5px] flex items-center py-3 px-10 xl:py-4 xl:px-14 gap-x-2 w-max mx-auto">
                  <SearchIcon />
                  <input
                    type="text"
                    className=" w-36 bg-[#F6F6F6] text-xs xl:text-sm outline-none"
                    placeholder="Search talnts.app"
                    onClick={enableLoginModal}
                  />
                </div>
              </div>

              <div className="gap-x-3 flex items-center md:ml-6 flex-[2] justify-end">
                <div className="lg:hidden" onClick={enableLoginModal}>
                  <SearchIcon2 />
                </div>
                {/* Login Here */}
                <button
                  className="capitalize font-bold px-3 md:px-10 py-1 md:py-3 bg-tech-blue1 text-white rounded-full text-sm md:text-base"
                  onClick={enableLoginModal}
                >
                  login
                </button>
                <button
                  className="capitalize font-bold px-3 md:px-10 py-1 md:py-3 bg-white text-tech-blue1 rounded-full text-sm md:text-base"
                  onClick={() => navigate("/")}
                >
                  get started
                </button>
              </div>
            </div>
          </div>

          <div className="bg-[#F5F6FF] w-full p-3">
            <Suspense fallback={<PageLoader />}>
              <Outlet context={enableLoginModal} />
            </Suspense>
          </div>
        </div>
        <Modal open={loginModal} handleClose={handleCloseLoginModal}>
          <div className="md:p-10 bg-white rounded-xl">
            <div className=" bg-white rounded-xl w-[97vw] md:w-[50vw] lg:w-[30vw] p-4 text-center">
              <p className=" text-tech-blue1 font-bold text-2xl lg:text-4xl">
                Login
              </p>
              <hr className="border-2 border-tech-blue1 w-[88.5px] mx-auto mt-1 mb-5" />
              <p>Kindly login or register an account to continue </p>
              <form className="space-y-6 mt-10" onSubmit={handleSubmit}>
                <InputField
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Email"
                  required
                  onChange={handleChange}
                  value={loginData.email}
                />

                <InputField
                  type={"password"}
                  name="password"
                  placeholder="Password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={handleChange}
                  value={loginData.password}
                />
                <div>
                  <AuthButton type="submit">
                    {isLoading ? "Loading..." : "LOGIN"}
                  </AuthButton>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-start gap-5">
                    <span className="text-sm text-[#474747] tracking-wider">
                      Not a member?{" "}
                      <a
                        className="text-[#173E9D]"
                        href="/signup"
                        onClick={() => {
                          cachePreLocation();
                        }}
                      >
                        Register
                      </a>
                    </span>
                  </div>
                  <a
                    href="/forgot-password"
                    className=" text-[#03045E] text-sm tracking-wider"
                  >
                    Forgot your password?
                  </a>
                </div>
              </form>
              <div className=" mt-8 mon-hover">
                <GoogleButton />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

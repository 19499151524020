import { useMutation, useQuery } from "react-query";
import { Api } from "../api/Api";
import { handleToastError } from "../utils/utils";

export const useGetNotifications = () => {
  return useQuery(
    "get-notifications",
    async () => await Api.notification.getNotifications()
  );
};

export const useUpdateNotificationView = () => {
  return useMutation((id) => Api.notification.updateNotificationView(id), {
    onError: (err) => {
      handleToastError(err);
    },
  });
};

export const useUpdateFcmToken = () => {
  return useMutation((data) => Api.notification.updateFcmToken(data), {
    onError: (err) => {
      handleToastError(err);
    },
  });
};
